import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { Grid } from "@mui/material";
import './Resume.css'

export default function ResumeSection(props) {
    let formatNumber = (number) => {
        if( number !== undefined && number !== null && number !== "") {
            return parseFloat(number).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
        return '0';
      }
  return (
    <div>
        <Box sx={ {display: 'flex', width:'100%'} }>
            <Fade in={props.isChecked}>
                  <div className='mt-30 wthd-100 '>
                        <div className='resume-container'>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <b><p>Automatic Measures</p></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <b><p>Manual Measures</p></b>
                                </Grid>                            
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>Constructed surface area</span>
                                </Grid>
                                <Grid item xs={1}>
                               <span><b>{formatNumber(props.data.SuperficieConstruida)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span>No. PA,s Teleoperator</span>
                                </Grid>
                                <Grid item xs={1}>
                                   <span><b>{formatNumber(props.data.PuestosTeleoperador)}</b></span>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={2}>
                                    <span>Repair and maintenance</span>
                                  </Grid>
                                  :                              
                                  <Grid item xs={2}>
                                    <span>RET (Real Estate Tax)</span>
                                  </Grid>
                                }

                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={1}>
                                    <span><b>{formatNumber(props.data.Mantenimiento)}</b></span>
                                </Grid>
                                  :                              
                                  <Grid item xs={1}>
                                     <span><b>{formatNumber(props.data.ImpuestoPropiedad)}</b></span>
                                  </Grid>
                                }

                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={2}>
                                    <span>Cleaning</span>
                                </Grid>
                                  :                              
                                  <Grid item xs={2}>
                                  <span>Activity Taxes (Garabage tax...)</span>
                              </Grid>
                                }

                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={1}>
                                    <span><b>{formatNumber(props.data.Limpieza)}</b></span>
                                </Grid>
                                  :                              
                                  <Grid item xs={1}>
                                  <span><b>{formatNumber(props.data.ImpuestoActividad)}</b></span>
                              </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>No. PA,s Coord/Superv</span>
                                </Grid>
                                <Grid item xs={1}>
                                    <span><b>{formatNumber(props.data.PuestosCoordinadorSupervisor)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span>No. PA,s Structure</span>
                                </Grid>
                                <Grid item xs={1}>
                                   <span><b>{formatNumber(props.data.PuestosEstructura)}</b></span>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={2}>
                                    <span>Security</span>
                                  </Grid>
                                  :                              
                                  <Grid item xs={2}>
                                  </Grid>
                                }
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={1}>
                                      <span><b>{formatNumber(props.data.Seguridad)}</b></span>
                                </Grid>
                                  :                              
                                  <Grid item xs={1}>
                                  </Grid>
                                }
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={2}>
                                   <span>Medical Center</span>
                                  </Grid>
                                  :                              
                                  <Grid item xs={2}>
                                  </Grid>
                                }
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                 ?
                                 <Grid item xs={1}>
                                      <span><b>{formatNumber(props.data.CentroMedico)}</b></span>
                                </Grid>
                                  :                              
                                  <Grid item xs={1}>
                                  </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>No. Seat's Meeting Room</span>
                                </Grid>
                                <Grid item xs={1}>
                               <span><b>{formatNumber(props.data.AsientosSalaReuniones)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span>No. Seat's Training</span>
                                </Grid>
                                <Grid item xs={1}>
                                   <span><b>{formatNumber(props.data.AsientosTraining)}</b></span>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Electricity</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.Electricidad)}</b></span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                    <span>Water</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.Agua)}</b></span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>No. Seat,s Break Rooms</span>
                                </Grid>
                                <Grid item xs={1}>
                               <span><b>{formatNumber(props.data.AsientosOficinaComedorDescanso)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span>Community Cost/Equivalent</span>
                                </Grid>
                                <Grid item xs={1}>
                                   <span><b>{formatNumber(props.data.CosteComunitario)}</b></span>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Communications</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.Comunicaciones)}</b></span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Office supplies</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={1}>
                                            <span><b>{formatNumber(props.data.MaterialOficina)}</b></span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>No. Seat,s Other uses</span>
                                </Grid>
                                <Grid item xs={1}>
                               <span><b>{formatNumber(props.data.AsientosOtros)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span>No. Parking places</span>
                                </Grid>
                                <Grid item xs={1}>
                                   <span><b>{formatNumber(props.data.PlazasParking)}</b></span>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Transports and transfers</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.TransportesTraslados)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Office technical staff</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                            <span><b>{formatNumber(props.data.PersonalTecnico)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <span>Monthly Rent Amount</span>
                                </Grid>
                                <Grid item xs={1}>
                                    <span><b>{formatNumber(props.data.AlquilerMensual)}</b></span>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>Network Electronics</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.RedElectrica)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={2}>
                                            <span>Others</span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                            <span><b>{formatNumber(props.data.Otros)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                    <Grid item xs={2}>
                                        <span>RET (Real Estate Tax)</span>
                                    </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                        <span><b>{formatNumber(props.data.ImpuestoPropiedad)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={2}>
                                            <span>Activity Taxes (Garabage tax...)</span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={2}>
                                    </Grid>
                                    }
                                    {props.user?.profile == 'Admin' || props.user?.profile == 'Corporate User'
                                    ?
                                        <Grid item xs={1}>
                                            <span><b>{formatNumber(props.data.ImpuestoActividad)}</b></span>
                                        </Grid>
                                    :                              
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </div>
            </Fade>
        </Box>
    </div>
  );
}