import getObject from '../../../Common/get';

//function method that get data maintenance filter for each type
export let getFilteredMaintenance = async(type) => {
    let maintenances=[];
    let models=[];
	
    switch(type) {
        //option for energetic certificate
        case "EGT":
			let energetic = await getObject("certificadosEnergeticosCog", null, null);
			maintenances.push({
				code: "EGT",
				data: energetic.data.respuesta
			});
            break;
        //option for exeption certificate
        case "EXT":
			let exeption = await getObject("certificadosExencionCog", null, null);
			maintenances.push({
				code: "EXT",
				data: exeption.data.respuesta
			});
            break;
        //option for index
        case "RT":
			let index = await getObject("indicesCog", null, null);
			maintenances.push({
				code: "RT",
				data: index.data.respuesta
			});
            break;
    }

    //it's necesary create a litle model for show in web front
    if(maintenances != null && maintenances.length > 0) {
        maintenances[0].data.forEach(value => {
                //all datas for show in web site.
                let model = {
                    Id: value.Id,
                    Nombre: value.Nombre,
                    UsuarioInsercion: value.UsuarioInsercion,
                    UsuarioModificacion: value.UsuarioModificacion,
                    FechaInsercion: value.FechaInsercion,
                    Type: type
                }
    
                models.push(model);
        }); 
    }
    
    return new Promise((resolve, reject) => { 
        (models != null)
         ? resolve(models)
         : resolve([]);
    })
}