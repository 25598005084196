import axios from 'axios'
import {
    REACT_APP_AWS_API_MAINTENANCE_GET_RENTAL_REVIEW,
    REACT_APP_AWS_API_MAINTENANCE_GET_ENERGETIC_CERTIFICATE,
    REACT_APP_AWS_API_MAINTENANCE_GET_EXENCTION_CERTIFICATE} from '../../../config'


let getRentalReview = () => {
    return new Promise((resolve, reject) => { 
        axios.get(REACT_APP_AWS_API_MAINTENANCE_GET_RENTAL_REVIEW).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

let getExceptionCertificate = () => {
    return new Promise((resolve, reject) => { 
        axios.get(REACT_APP_AWS_API_MAINTENANCE_GET_EXENCTION_CERTIFICATE).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

let getEnergeticCertificate = () => {
    return new Promise((resolve, reject) => { 
        axios.get(REACT_APP_AWS_API_MAINTENANCE_GET_ENERGETIC_CERTIFICATE).then(response=> {
            resolve(response, "200", 'Success');
        }).catch(error => {
            resolve([], '404', error.response);
        });
    })
}

export {
    getRentalReview,
    getExceptionCertificate,
    getEnergeticCertificate
}