import React from 'react';
import styled from 'styled-components';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 13px;
  margin-bottom: 4px;
`;

const Value = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const Label = ({ title, value }) => {
  return (
    <LabelContainer>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </LabelContainer>
  );
};

export default Label;
