import React, { Component } from 'react'
import { TextField, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '../../../Components/Alerts/message'

/* Components */
import ButtonSend from '../../../Components/Buttons/ButtonSend'

/* Properties */
import filtercountries from '../../Common/countries';
import PostData from '../../../../Controller/Custom/Platform/post'

/* Styles */
import 'bootstrap/dist/css/bootstrap.min.css'
import './Platform.css'

class Platform extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            idPais: 0,
            disabled: false,
            loading: false,
            user: props.user,
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            ssdata:{
                id: '',
                value: '',
                name: ''
            },
            gsdata:{
                countries:[]
            },
            createdata:{
                nombrePlataforma: '',
                paisPlataforma: '',
                domicilioPlataforma: '',
                ciudadPlataforma: '',
            }   
        };
        this.handleCRChange = this.handleCRChange.bind(this);
      }

    // Method that send data user and create
    sendDataModal=async()=>{
        let response = await PostData(this.state.createdata);
        if(response.status === 200) {
            this.setState({
                alerts: {
                    open: true,
                    severity: 'success',
                    message: response.data.mensaje
                },
                disabled: false,
                loading: false,
                createdata: null
            });
        } else{
            this.setState({
                alerts: {
                    open: true,
                    severity: 'error',
                    message: response.data.mensaje
                },
                disabled: false,
                loading: false,
                createdata: null
            });
        }
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }
 
    // event for selects all values for create data period
    handleCRChange=async e=>{
        await this.setState({
            createdata:{
                ...this.state.createdata,
                [e.target.name]: e.target.value
            }
        });

        console.log(this.state.createdata);
    }

    // Initialize primary components necesary for interact with data.
    componentDidMount=async()=>{
        let response = await filtercountries(this.state.user);
        this.setState({
            gsdata: {
                countries: response,
            }
        });
    }

    render(){
        return(
        <div className="form-group">
            <div className='mb-bt15'>
            <div className='mb-10'>
                    <Box sx={{ maxWidth: '100%' }}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="nombrePlataforma"
                                name="nombrePlataforma"
                                variant="filled"
                                label="Platform Name"
                                value={this.state.createdata?.nombrePlataforma}
                                onChange={this.handleCRChange}
                                />
                        </FormControl>
                    </Box>
                </div>
                <div className='mb-10'>
                    <Box sx={{ maxWidth: '100%' }}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="domicilioPlataforma"
                                name="domicilioPlataforma"
                                variant="filled"
                                label="Adresss"
                                value={this.state.createdata?.domicilioPlataforma}
                                onChange={this.handleCRChange}
                                />
                        </FormControl>
                    </Box>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    id="ciudadPlataforma"
                                    name="ciudadPlataforma"
                                    variant="filled"
                                    label="City"
                                    value={this.state.createdata?.ciudadPlataforma}
                                    onChange={this.handleCRChange}
                                    />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="countrylabel">Country</InputLabel>
                            <Select
                                labelId="countrylabel"
                                id="paisPlataforma"
                                name="paisPlataforma"
                                label="Country"
                                value={this.state.createdata?.paisPlataforma}
                                onChange={this.handleCRChange}
                                >
                                {this.state.gsdata.countries.sort((a, b) => a.Nombre > b.Nombre).map((country, index)=> {
                                    return(
                                        <MenuItem key={country.Id} value={country.Nombre}>{country.Nombre}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={1}>
                <Grid item xs={6}>
                    <div className='section-button-save'>
                        <ButtonSend event={()=>{this.setState({disabled: true, loading: true}); this.sendDataModal()}} disabled={this.state.disabled} loading={this.state.loading} name="Send"/> 
                    </div>
                </Grid>
            </Grid>
            <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
        </div>
        )
    }
}

export default Platform