import * as React from "react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#162C5E",
  "&:hover": {
    backgroundColor: "#EDEDED"
  },
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

const ExoticButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#FF5722"),
  backgroundColor: "#FF5722",
  "&:hover": {
    backgroundColor: "#DA4A1C"
  },
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

const BlueButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#1A346D"),
  backgroundColor: "#1A346D",
  "&:hover": {
    backgroundColor: "#122349"
  },
  borderRightColor: "white",
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

export default function ButtonGroupFiles(props) {
    const buttons = [
        <WhiteButton key="1" startIcon={<CleaningServicesIcon />} disabled={props.disable} onClick={props.clevent} variant="contained">Clean Filters</WhiteButton>,
        (props.name != null && props.name != undefined && props.name != '' && props.user?.profile != "Reading User")
            ? <WhiteButton key="2" startIcon={<AddCircleIcon />} disabled={props.disable} onClick={props.addevent} variant="contained">{props.name}</WhiteButton> : '',
        <WhiteButton key="3" startIcon={<AutorenewIcon />} style={{borderRightColor: "white"}} disabled={props.disable} onClick={props.stevent} variant="contained">Status File</WhiteButton>,
        props.user?.profile == "Admin" ? <BlueButton key="4" startIcon={<CloudUploadIcon />} disabled={props.disable} onClick={props.upevent} variant="contained">Upload File</BlueButton> : '',
        <BlueButton key="5" startIcon={<CloudDownloadIcon />} disabled={props.disable} onClick={props.ddevent} variant="contained">Download File</BlueButton>
    ];

  return (
    <ButtonGroup size="large" aria-label="large button group">{buttons}</ButtonGroup>
  );
}