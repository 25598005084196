import React, { useState } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useForm, Controller } from "react-hook-form";

import { Box, Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import ButtonRequest from '../../../../Components/Buttons/ButtonRequest'
import Alert from '../../../../Components/Alerts/message'
import DecimalTextField from '../../../../Components/Input/DecimalTextField'

/* Data */
import updateMeasures from '../../Business/Events/measuresput';
import { getMeasures } from '../../Business/Events/measuresget';

import './UpdateMeasures.css'

export default function UpdateMeasure({isOpen, user, data, deleteRow, detailMeasures, detailResume}) {
    const [open, setOpen] = useState(isOpen);
    const [formulary, setFormulary] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: ''});
    const {control, handleSubmit} = useForm({model: "all"});
    const [mantenimiento, setMantenimiento] = useState('');
    const [superficieConstruida, setSuperficieConstruida] = useState('');
    const [puestosTeleoperador, setPuestosTeleoperador] = useState('');
    const [puestosCoordinadorSupervisor, setPuestosCoordinadorSupervisor] = useState('');
    const [puestosEstructura, setPuestosEstructura] = useState('');
    const [limpieza, setLimpieza] = useState('');
    const [seguridad, setSeguridad] = useState('');
    const [centroMedico,setCentroMedico] = useState('');
    const [electricidad,setElectricidad] = useState('');
    const [agua,setAgua] = useState('');
    const [comunicaciones,setComunicaciones] = useState('');
    const [materialOficina,setMaterialOficina] = useState('');
    const [transportesTraslados,setTransportesTraslados] = useState('');
    const [personalTecnico,setPersonalTecnico] = useState('');
    const [redElectrica,setRedElectrica] = useState('');
    const [otrosManual,setOtrosManual] = useState('');
    const [asientosSalaReuniones,setAsientosSalaReuniones] = useState('');
    const [asientosTraining,setAsientosTraining] = useState('');
    const [asientosOficinaComedorDescanso,setAsientosOficinaComedorDescanso] = useState('');
    const [asientosOtros,setAsientosOtros] = useState('');
    const [plazasParking,setPlazasParking] = useState('');
    const [alquilerMensual, setAlquilerMensual] = useState('');
    const [impuestoapropiado,setImpuestoApropiado] = useState('');
    const [actividadimpuesto,setActividadImpuesto] = useState('');
    const [costeComunitario,setCosteComunitario] = useState('');

    const handleOnSubmit = async(evt) => {
        setDisabled(true);
        setLoading(true);

        let medidasAuto = {
            SuperficieConstruida: superficieConstruida,
            PuestosTeleoperador: puestosTeleoperador,
            PuestosCoordinadorSupervisor: puestosCoordinadorSupervisor,
            PuestosEstructura: puestosEstructura,
            AsientosSalaReuniones: asientosSalaReuniones,
            AsientosTraining: asientosTraining,
            AsientosOficinaComedorDescanso: asientosOficinaComedorDescanso,
            AsientosOtros: asientosOtros,
            PlazasParking: plazasParking,
            AlquilerMensual: alquilerMensual,
            Impuestos: 0,
            CosteComunitario: costeComunitario,
            UsuarioModificacion: user?.email,
        }

        let medidasManual = {
            Mantenimiento: mantenimiento,
            Limpieza: limpieza,
            Seguridad: seguridad,
            CentroMedico: centroMedico,
            Electricidad: electricidad,
            Agua: agua,
            Comunicaciones: comunicaciones,
            MaterialOficina: materialOficina,
            TransportesTraslados: transportesTraslados,
            PersonalTecnico: personalTecnico,
            RedElectrica: redElectrica,
            ImpuestoActividad: actividadimpuesto,
            ImpuestoPropiedad:impuestoapropiado,
            Otros: otrosManual,
            UsuarioModificacion: user?.email,
        }

        let properties = {
            formulary: {
                medidasAuto: medidasAuto,
                medidasManual: medidasManual
            },
            idperiodo: data.IdPeriodoPais,
            idespacio: data.idEspacio,
            data: data.IdPeriodoPais + "/" + data.idEspacio
        };

        updateMeasures("medidasUpdatedCog", user, properties).then(response => {
            setAlert({ open: true, severity: response.severity, message: response.message});
            detailResume();
            setDisabled(false);
            setLoading(false);
            openModal();
        }).catch(error => {
            setAlert({ open: true, severity: 'error', message: error.response.data});
            setDisabled(false);
            setLoading(false);
            openModal();
        });
    };

    const getDataMeasures = () => {
        if (data) {  // Esto cubre tanto null como ''
            const properties = `${data.IdPeriodoPais}/${data.idEspacio}`;
    
            getMeasures("medidasPeriodoEspacioCog", user, properties).then((value) => {
                if (value) {
                    const safeGet = (obj, key) => (obj && obj[key] !== null) ? obj[key] : '';
                    const formData = {
                        SuperficieConstruida: safeGet(value.medidasAuto, 'SuperficieConstruida'),
                        PuestosTeleoperador: safeGet(value.medidasAuto, 'PuestosTeleoperador'),
                        PuestosCoordinadorSupervisor: safeGet(value.medidasAuto, 'PuestosCoordinadorSupervisor'),
                        PuestosEstructura: safeGet(value.medidasAuto, 'PuestosEstructura'),
                        AsientosSalaReuniones: safeGet(value.medidasAuto, 'AsientosSalaReuniones'),
                        AsientosTraining: safeGet(value.medidasAuto, 'AsientosTraining'),
                        AsientosOficinaComedorDescanso: safeGet(value.medidasAuto, 'AsientosOficinaComedorDescanso'),
                        AsientosOtros: safeGet(value.medidasAuto, 'AsientosOtros'),
                        PlazasParking: safeGet(value.medidasAuto, 'PlazasParking'),
                        AlquilerMensual: safeGet(value.medidasAuto, 'AlquilerMensual'),
                        CosteComunitario: safeGet(value.medidasAuto, 'CosteComunitario'),
                        Mantenimiento: safeGet(value.medidasManual, 'Mantenimiento'),
                        Limpieza: safeGet(value.medidasManual, 'Limpieza'),
                        Seguridad: safeGet(value.medidasManual, 'Seguridad'),
                        CentroMedico: safeGet(value.medidasManual, 'CentroMedico'),
                        Electricidad: safeGet(value.medidasManual, 'Electricidad'),
                        Agua: safeGet(value.medidasManual, 'Agua'),
                        ImpuestoActividad: safeGet(value.medidasManual, 'ImpuestoActividad'),
                        ImpuestoPropiedad: safeGet(value.medidasManual, 'ImpuestoPropiedad'),
                        Comunicaciones: safeGet(value.medidasManual, 'Comunicaciones'),
                        MaterialOficina: safeGet(value.medidasManual, 'MaterialOficina'),
                        TransportesTraslados: safeGet(value.medidasManual, 'TransportesTraslados'),
                        PersonalTecnico: safeGet(value.medidasManual, 'PersonalTecnico'),
                        RedElectrica: safeGet(value.medidasManual, 'RedElectrica'),
                        Otros: safeGet(value.medidasManual, 'Otros')
                    };
                    setFormulary(formData);
    
                    // Si realmente necesitas configurar esos valores individualmente también:
                    setSuperficieConstruida(formData.SuperficieConstruida);
                    setPuestosTeleoperador(formData.PuestosTeleoperador);
                    setPuestosCoordinadorSupervisor(formData.PuestosCoordinadorSupervisor);
                    setPuestosEstructura(formData.PuestosEstructura);
                    setAsientosSalaReuniones(formData.AsientosSalaReuniones);
                    setAsientosTraining(formData.AsientosTraining);
                    setAsientosOficinaComedorDescanso(formData.AsientosOficinaComedorDescanso);
                    setAsientosOtros(formData.AsientosOtros);
                    setPlazasParking(formData.PlazasParking);
                    setAlquilerMensual(formData.AlquilerMensual);
                    setCosteComunitario(formData.CosteComunitario);
                    setMantenimiento(formData.Mantenimiento);
                    setLimpieza(formData.Limpieza);
                    setSeguridad(formData.Seguridad);
                    setCentroMedico(formData.CentroMedico);
                    setElectricidad(formData.Electricidad);
                    setAgua(formData.Agua);
                    setImpuestoApropiado(formData.ImpuestoPropiedad);
                    setActividadImpuesto(formData.ImpuestoActividad);
                    setAgua(formData.Agua);
                    setComunicaciones(formData.Comunicaciones);
                    setMaterialOficina(formData.MaterialOficina);
                    setTransportesTraslados(formData.TransportesTraslados);
                    setPersonalTecnico(formData.PersonalTecnico);
                    setRedElectrica(formData.RedElectrica);
                    setOtrosManual(formData.Otros);
                }
            });
        }
    }
    

    const openModal=()=>{
        setTimeout(() => {
            setOpen(!open); 
        }, (600));

        if(!open === true){
            getDataMeasures();
        }
    }

    const closeAlert=()=>{
        setAlert({ open: false});
    }

    const datadelete=()=>{
        deleteRow();
    }

    const dataview=()=>{
        detailMeasures();
    }

    return (
        <div className=''>
            {data.EstadoPeriodo !== 'Closed' ?
                <span>
                    {user?.profile != "Reading User" ? 
                        <IconButton aria-label="edit" onClick={()=> openModal()} style={{color: "rgb(113 132 160)"}}><EditIcon /></IconButton>
                    : ''}
                    {user?.profile == "Corporate User" || user?.profile == "Admin" ? 
                        <IconButton aria-label="delete" onClick={() => datadelete()} style={{color: "rgb(239 151 151)"}}><DeleteIcon /></IconButton>
                         : ''}
                </span>
                : ''
            }

            <IconButton aria-label="details" onClick={()=> {dataview()}} style={{color: "rgb(212 173 115)"}}><RemoveRedEyeIcon /></IconButton>

            <Alert open={alert.open} severity={alert.severity} message={alert.message} event={closeAlert}/>
            <Modal isOpen={open} style={{width: "100%", maxWidth: "95%"}}>
                <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                    <span style={{float: 'right'}} onClick={()=>openModal()}>x</span>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-group">
                        <div className='mb-bt15'>
                            <div className="containers">
                                <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                                    <div className="sub-title">
                                        <p>Manual Measures</p>
                                    </div>
                                    {user?.profile == "Corporate User" || user?.profile == "Admin"
                                    ? <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                <Controller
                                                    control={control}
                                                    name="Mantenimiento"
                                                    defaultValue={formulary.Mantenimiento}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Repair and maintenance"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={mantenimiento}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setMantenimiento(value)} />
                                                )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                control={control}
                                                name="Limpieza"
                                                defaultValue={formulary.Limpieza}
                                                render={({ field }) => (
                                                    <DecimalTextField
                                                        label="Cleaning"
                                                        variant="filled"
                                                        fullWidth
                                                        decimalCharacter=","
                                                        digitGroupSeparator="."
                                                        value={limpieza}
                                                        currencySymbol=""
                                                        onChange={(event, value)=> setLimpieza(value)} />
                                                )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                    control={control}
                                                    name="Seguridad"
                                                    defaultValue={formulary.Seguridad}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Security"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={seguridad}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setSeguridad(value)} />
                                                    )}
                                                    />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="CentroMedico"
                                                        defaultValue={formulary.CentroMedico}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Medical Center"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={centroMedico}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setCentroMedico(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="Electricidad"
                                                        defaultValue={formulary.Electricidad}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Electricity"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={electricidad}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setElectricidad(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                    control={control}
                                                    name="Agua"
                                                    defaultValue={formulary.Agua}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Water"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={agua}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAgua(value)} />
                                                    )}
                                                    />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="Comunicaciones"
                                                        defaultValue={formulary.Comunicaciones}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Communications"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={comunicaciones}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setComunicaciones(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="MaterialOficina"
                                                        defaultValue={formulary.MaterialOficina}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Office supplies"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={materialOficina}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setMaterialOficina(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="TransportesTraslados"
                                                        defaultValue={formulary.TransportesTraslados}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Transports and transfers"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={transportesTraslados}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setTransportesTraslados(value)} />
                                                        )}
                                                        />

                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="PersonalTecnico"
                                                        defaultValue={formulary.PersonalTecnico}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Office technical staff"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={personalTecnico}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setPersonalTecnico(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="RedElectrica"
                                                        defaultValue={formulary.RedElectrica}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Network Electronics"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={redElectrica}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setRedElectrica(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="impuestosapropiados"
                                                        defaultValue={formulary.ImpuestoPropiedad}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="RET (Real Estate Tax)"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={impuestoapropiado}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setImpuestoApropiado(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="actividadimpuestos"
                                                        defaultValue={formulary.ImpuestoActividad}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Activity Taxes (Garabage tax...)"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={actividadimpuesto}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setActividadImpuesto(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="OtrosManual"
                                                        defaultValue={formulary.OtrosManual}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Others"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={otrosManual}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setOtrosManual(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                        </Grid>
                                      </div>
                                    : <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="impuestosapropiados"
                                                        defaultValue={formulary.ImpuestoPropiedad}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="RET (Real Estate Tax)"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={impuestoapropiado}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setImpuestoApropiado(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Controller
                                                        control={control}
                                                        name="actividadimpuestos"
                                                        defaultValue={formulary.ImpuestoActividad}
                                                        render={({ field }) => (
                                                            <DecimalTextField
                                                                label="Activity Taxes (Garabage tax...)"
                                                                variant="filled"
                                                                fullWidth
                                                                decimalCharacter=","
                                                                digitGroupSeparator="."
                                                                value={actividadimpuesto}
                                                                currencySymbol=""
                                                                onChange={(event, value)=> setActividadImpuesto(value)} />
                                                        )}
                                                        />
                                            </Grid>
                                        </Grid>
                                      </div>}
                                    
                                    <div className="sub-title section" style={{marginTop: "20px"}}>
                                        <p>Automatic Measures</p>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="SuperficieConstruida"
                                                    defaultValue={parseFloat(formulary.SuperficieConstruida).toFixed(2)}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Constructed surface area"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={superficieConstruida}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setSuperficieConstruida(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="PuestosTeleoperador"
                                                    defaultValue={formulary.PuestosTeleoperador}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. PA,s Teleoperator"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={puestosTeleoperador}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setPuestosTeleoperador(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="PuestosCoordinadorSupervisor"
                                                    defaultValue={formulary.PuestosCoordinadorSupervisor}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. PA,s Coord/Supervisor"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={puestosCoordinadorSupervisor}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setPuestosCoordinadorSupervisor(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="PuestosEstructura"
                                                    defaultValue={formulary.PuestosEstructura}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. PA,s Structure"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={puestosEstructura}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setPuestosEstructura(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="AsientosSalaReuniones"
                                                    defaultValue={formulary.AsientosSalaReuniones}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. Seat's Meeting Room"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={asientosSalaReuniones}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAsientosSalaReuniones(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="AsientosTraining"
                                                    defaultValue={formulary.AsientosTraining}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. Seat's Training"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={asientosTraining}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAsientosTraining(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="AsientosOficinaComedorDescanso"
                                                    defaultValue={formulary.AsientosOficinaComedorDescanso}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. Seat,s Break Rooms"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={asientosOficinaComedorDescanso}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAsientosOficinaComedorDescanso(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="AsientosOtros"
                                                    defaultValue={formulary.AsientosOtros}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. Seat,s Other uses"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={asientosOtros}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAsientosOtros(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="PlazasParking"
                                                    defaultValue={formulary.PlazasParking}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="No. Parking places"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={plazasParking}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setPlazasParking(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="AlquilerMensual"
                                                    defaultValue={formulary.AlquilerMensual}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Monthly Rent Amount"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            variant="filled"
                                                            value={alquilerMensual}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setAlquilerMensual(value)} />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Controller
                                                    control={control}
                                                    name="communityCost"
                                                    defaultValue={formulary.CosteComunitario}
                                                    render={({ field }) => (
                                                        <DecimalTextField
                                                            label="Community Cost/Equivalent"
                                                            variant="filled"
                                                            fullWidth
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            value={costeComunitario}
                                                            currencySymbol=""
                                                            onChange={(event, value)=> setCosteComunitario(value)} />
                                                    )}
                                                    />
                                        </Grid>  
                                    </Grid>
                                        <div className='section section-button mt-20'>
                                            <div className='section-button-save'>
                                                <ButtonRequest name="Send" disabled={disabled} loading={loading} />
                                            </div>
                                        </div>                        
                                </Box>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div> 
    );
}