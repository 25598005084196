import React, { Component } from 'react'

import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

/* Button */
import ButtonAction from '../../../Components/Buttons/ButtonAction'
import Pagination from '../../../Components/Table/Utils/Pagination'
import CsLoader from '../../../Components/Loading/Loader'
import Alert from '../../../Components/Alerts/message'
import CreatePeriodModal from './Modal/CreatePeriod'

/* Header */
import datas from '../Data/THeader'

/* Data */
import Months from '../../../../Controller/Generic/getMonths'
import filtercountries from '../../Common/countries';
import { getPeriods, getPeriodsById } from '../Business/Events/periodget';
import { delPeriods } from '../Business/Events/perioddelete';
import { updatePeriod } from '../Business/Events/periodput';

import 'bootstrap/dist/css/bootstrap.min.css'
import './Period.css'

class Period extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            pagination: {
                currentpage: 1,
                recordperpage: 14
            },
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            modals:{
                iscreatemodalopen: false,
                isdeletemodalopen: false,
                isopenmodalperiod: false,
                deletemodal: {
                    title: '',
                    message: ''
                },
            },
            user: props?.user,
            ssdata:{
                data: [],
                ids: '',
                value: '',
                name: ''
            },
            gsdata:{
                countries:[],
                months:[],
                periods:[],
                locations:[],
                workcenters:[]
            },
            getdata:[],
            createdata:{
                FechaInsercion: new Date(new Date().getUTCFullYear, new Date().getUTCMonth(), 1),
                FechaModificacion: null,
                UsuarioInsercion: '',
                UsuarioModificacion: null,
                IdPais: '',
                Fecha_Periodo:'',
                Pais:{
                    Nombre: ''
                }
            },
            selectdata: {
                id: ''
            }
        };
        this.wrapper = React.createRef();
        this.handleSLChange = this.handleSLChange.bind(this);
        this.handleCRChange = this.handleCRChange.bind(this);
        this.handleDTChange = this.handleDTChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
      }
    
    // get data period
    getData=async(data) =>{
         //function method for get measures
         let response = await getPeriods("periodosPaisAllCog", this.state.user, null);
         this.setState({
             getdata: response,
             isloading: false
         });
    }

    // get data period by country
    getDataById=async(country) =>{
         //function method for get measures
         let response = await getPeriodsById("periodosAllPaisCog", this.state.user, country);
         this.setState({
             getdata: response,
             isloading: false
         });
        this.pageRender(1);
    }

    // select data period
    selectData=(data)=>{
        this.setState({
          selectdata: {
            id: data.Id,
            status: data.RES_EstadoPeriodo.Alias
          }
        });
    }

    // delete data period.
    deleteData=async()=>{
        let response = await delPeriods("periodosCog", this.state.selectdata);
        this.setState({alerts: {
            open: response.open,
            severity: response.severity,
            message: response.message
        }});

        this.cleanComponents();
        this.setState({modals: {isdeletemodalopen: false }});
    }

    // method for open or close period
    putData=async()=>{
        let properties = {
            formulary: {
                Periodos: [
                    {Id: this.state.selectdata.id}
                ],
                Estado: (this.state.selectdata.status === "Open" ? "Closed" : "Open"),
                UsuarioModificacion: this.state.user?.email
            }      
        }
        
        let response = await updatePeriod("periodosPaisUpdatedCog", this.state.user, properties);
        this.setState({alerts: {
            open: response.open,
            severity: response.severity,
            message: response.message
        }});
        this.cleanComponents();
        this.setState({modals: {isdeletemodalopen: false }});
    }

    // event for selects all values for create data period
    handleCRChange=async e=>{
        await this.setState({
            createdata:{
                ...this.state.createdata,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSLChange=async(event, values)=>{
        let countriesId = '';
        if(values.length === 0 && this.state.user?.profile == process.env.REACT_APP_ROLE_REG
            || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
            ||  this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            this.getDataFilterByRole();
        } else {
            values.map(element => {
                if(countriesId === '') {
                    countriesId = element.Id
                } else {
                    countriesId = countriesId + ',' + element.Id
                }
            });

            this.setState({
                ssdata: {
                    data: values,
                    ids: countriesId
                },
                isloading: true
            });
    
            this.getDataById(countriesId);
        }
    }

    handleDTChange(date){
        this.setState({
            createdata:{
                ...this.state.createdata,
                FechaInsercion: date
            }
        })
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }

    cleanComponents=()=>{
        this.setState({
            ssdata: {
                data: [],
                ids: null
            }
        });

        if(this.state.user?.profile == process.env.REACT_APP_ROLE_REG
            || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
            ||  this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            this.getDataFilterByRole();
        } else if (this.state.user?.profile == process.env.REACT_APP_ROLE_COR || this.state.user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getData(null);
        } else {
            this.setState({
                getdata: [],
                isloading: false
            });
        }
    }

    renderDataByRole=(id)=>{
        if(this.state.user?.profile == process.env.REACT_APP_ROLE_REG
            || this.state.user?.profile == process.env.REACT_APP_ROLE_PAS
            ||  this.state.user?.profile == process.env.REACT_APP_ROLE_BAS) {
            this.getDataFilterByRole();
        } else if (this.state.user?.profile == process.env.REACT_APP_ROLE_COR || this.state.user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getData(id);
        } else {
            this.setState({
                getdata: [],
                isloading: false
            });
        }
    }

    // render pagination functionality for data table.
    pageRender(page) {
        this.setState({
            pagination: {
                currentpage: page
            }
        });
    }

    updateUser=(data)=> {
        this.setState({user: data});
    }

    getDataFilterByRole() {
        let countriesId = '';
        let countryArray = '';

        if (this.state.user?.locale.includes(',')) {
            countryArray = this.state.user?.locale.split(',');
        } else {
            countryArray = this.state.user?.locale;
        }

        let countries = this.state.gsdata.countries.filter((element) => { return countryArray.includes(element.Nombre); });
        countries.map(element => {
            if (countriesId === '') {
                countriesId = element.Id;
            } else {
                countriesId = countriesId + ',' + element.Id;
            }
        });

        this.setState({
            ssdata: {
                data: [],
                id: countriesId
            },
            isloading: true
        });

        this.getDataById(countriesId);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.user != this.state.user) {
            setTimeout(() => {
                this.updateUser(prevProps.user);
                this.renderInitialData(prevProps.user);
            }, 2000);
        }
    }

    // init all data.
    componentDidMount=async()=>{
        await this.renderInitialData(this.state.user, false);
    }

    renderInitialData = async(user, isrender) => {
        this.setState({ isloading: true });

        let response = await filtercountries(user);
        if (user?.profile == process.env.REACT_APP_ROLE_REG
                || user?.profile == process.env.REACT_APP_ROLE_PAS
                || user?.profile == process.env.REACT_APP_ROLE_BAS) {
            let countriesId = '';
            let countryArray = '';

            if (user?.locale.includes(',')) {
                countryArray = user?.locale.split(',');
            } else {
                countryArray = user?.locale;
            }

            let countries = response.filter((element) => { return countryArray.includes(element.Nombre); });
            countries.map(element => {
                if (countriesId === '') {
                    countriesId = element.Id;
                } else {
                    countriesId = countriesId + ',' + element.Id;
                }
            });

            this.getDataById(countriesId);
        } else if (user?.profile == process.env.REACT_APP_ROLE_COR || user?.profile == process.env.REACT_APP_ROLE_ADM) {
            this.getData(null);
        } else {
            if(!isrender) {
                this.setState({
                    getdata: [],
                    isloading: false
                });
            }
        }
        
        this.setState({
            gsdata: {
                countries: response != undefined && response != null ? response : [],
                months: Months
            },
        });
    }

    // main method for print component on screen
    render(){
        const indexOfLastRecord = this.state.pagination.currentpage * 12;
        const indexOfFirstRecord = indexOfLastRecord - 12;
        const currentData = this.state.getdata.sort(function(a, b) { return new Date(a.RRHH_Periodo.FechaPeriodo) < new Date(b.RRHH_Periodo.FechaPeriodo)}).slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.getdata.length / 12);

        return(
            <div ref={this.wrapper}>
                <div className='mb-bt15'>
                    <Box sx={{ maxWidth: 300 }}>
                        <FormControl fullWidth>
                            <Autocomplete
                                    multiple
                                    id="country"
                                    name="country"
                                    label="Country"
                                    disabled={this.state.user?.profile === "Country User"
                                        || this.state.user?.profile === "Reading User"}
                                    options={this.state.gsdata?.countries}
                                    getOptionLabel={(option) => option.Nombre}
                                    value={this.state.ssdata?.data}
                                    filterSelectedOptions
                                    onChange={this.handleSLChange}
                                    getOptionDisabled={(option) =>
                                        getCountriesByRole(this.state.user, option)
                                    }
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                    />
                                    )}
                                />
                        </FormControl>
                    </Box>
                </div>
                <div>
                    <div className='mb-10 txt-righ'>
                        <CreatePeriodModal
                         isOpen={this.state.modals.iscreatemodalopen} 
                         data={this.state.getdata} dataRender={this.renderDataByRole.bind(this)}
                         id={this.state.ssdata.id}
                         cleanComponents={this.cleanComponents.bind(this)}
                         user={this.state.user} />
                    </div>
                </div>
                <div>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                {datas.map((data, index) => {
                                    return(<th key={data.name} className={data.style}>{data.name}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>	
                            {this.state.isloading === false
                                ? (currentData.length > 0
                                     ? currentData.map((period, index)=> {
                                        return(
                                            <tr key={index}>
                                                <td>{new Date(period.RRHH_Periodo.FechaPeriodo).getFullYear()}</td>
                                                <td>{getMonthName(period.RRHH_Periodo.FechaPeriodo)}</td>
                                                <td>{period.Pais.Nombre}</td>
                                                <td>{period.RES_EstadoPeriodo.Alias}</td>
                                                <td>
                                                    <ButtonAction
                                                        canEdit={false}
                                                        canDelete={false}
                                                        canSeeDetails={false}
                                                        canOpenPeriod={(period.RES_EstadoPeriodo.Alias === "Open" && (this.state.user?.profile === 'Corporate User' || this.state.user?.profile === 'Regional User' || this.state.user?.profile === 'Country User'))
                                                         || (this.state.user?.profile === 'Admin')}
                                                        editRow={''}
                                                        openPeriod={()=>{this.selectData(period); this.setState({modals: {isopenmodalperiod: true }})}}
                                                        deleteRow={()=>{this.selectData(period); this.setState({modals: {isdeletemodalopen: true }})}}
                                                        detailRow={''} />
                                                </td>
                                            </tr>
                                        )                   
                                    })
                                    : <tr><td colSpan={6}  style={{borderBottom: "0px", textAlign: "left" }}><div style={{background:'white'}}>Not have registers.</div></td></tr>) 
                                : <CsLoader />
                            }
                        </tbody>
                    </table>
                    <Pagination pagesCount={nPages} currentPage={this.state.pagination.currentpage} setCurrentPage={this.pageRender.bind(this)}/>
                    <Modal isOpen={this.state.modals.isdeletemodalopen}>
                        <ModalBody>
                            Are you sure that you want delete the following period?
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={()=>this.deleteData()}>Yes</button>
                            <button className="btn btn-secundary" onClick={()=>this.setState({modals: {isdeletemodalopen: false }})}>No</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modals.isopenmodalperiod}>
                        <ModalBody>
                            Are you sure that you want change the status of the following period?
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={()=>this.putData()}>Yes</button>
                            <button className="btn btn-secundary" onClick={()=>this.setState({modals: {isopenmodalperiod: false }})}>No</button>
                        </ModalFooter>
                    </Modal>
                    <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
                </div>
            </div>
        )
    }
}

const getMonthName = (time) => {
    const date = new Date(time);
    date.getMonth();
  
    return date.toLocaleString('en-US', { month: 'long' });
  }

const getCountriesByRole = (data, option) => {
    if (data?.profile == process.env.REACT_APP_ROLE_REG
        || data?.profile == process.env.REACT_APP_ROLE_PAS
        || data?.profile == process.env.REACT_APP_ROLE_BAS) {
        if(data.locale.includes(',')) {
            let countries = data.locale.split(',');
            console.log(countries);
            return !countries.includes(option.Nombre);
        }else {
            return option.Nombre !== data.locale;
        }
    }
}

export default Period