import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_DELETE} from '../../../config'

let deleteSpace = async(id, user) => {
    return new Promise((resolve, reject) => {    
        axios.delete(REACT_APP_AWS_API_SPACE_DELETE + id + "/" + user?.profile + "/" + user?.location)
        .then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default deleteSpace