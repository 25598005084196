const data = [
    {
        name: 'Module Name',
        style: 'th-month'
    },
    {
        name: 'Module Code',
        style: 'th-month'
    },
    {
        name: 'Module Country',
        style: ''
    },
    {
        name: 'Module Location',
        style: ''
    },
    {
        name: 'Action',
        style: 'th-action'
    },
]

export default data