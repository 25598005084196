import getObject from '../../../Common/get';

/**
 * Función genérica para obtener datos desde un AWS Lambda.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @param {string} properties - Propiedad que se incluirá en la solicitud.
 * @param {function} sorter - Función opcional de ordenamiento para los datos.
 * @returns {array|object} - Devuelve una lista de objetos o un objeto, dependiendo de la respuesta.
 */
const fetchData = async (method, user, properties, sorter) => {
    try {
        const response = await getObject(method, user, properties);
        if (response && response.status === 200) {
            return sorter ? response.data.respuesta.sort(sorter) : response.data.respuesta;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        // Puedes manejar el error de otra manera si lo prefieres.
    }
    return [];
}

// Funciones de ordenamiento para diferentes casos.
const sortPeriod = (a, b) => a.FechaPeriodo < b.FechaPeriodo ? 1 : -1;
const sortName = (a, b) => a.Nombre > b.Nombre ? 1 : -1;
const sortPeriodProperty = (a, b) => a.Periodo < b.Periodo ? 1 : -1;

/**
 * Función para obtener medidas.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @returns {array} - Devuelve una lista de medidas.
 */
export const getMeasures = async (method, user, properties) => fetchData(method, user, properties);

/**
 * Función para obtener periodos.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @returns {array} - Devuelve una lista de periodos ordenados por fecha.
 */
export const getPeriod = async (method, user) => fetchData(method, user, undefined, sortPeriod);

/**
 * Función para obtener centros de trabajo.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @returns {array} - Devuelve una lista de centros de trabajo ordenados por nombre.
 */
export const getWorkCenters = async (method, user) => fetchData(method, user, undefined, sortName);

/**
 * Función para obtener medidas filtradas.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @param {object} props - Propiedades para el filtrado.
 * @returns {array} - Devuelve una lista de medidas filtradas y ordenadas por periodo.
 */
export const getFilterMeasures = async (method, user, props) => {
    const property = `${props.locations || null}/${props.workcenters || null}/${props.ids || null}/${props.periods || null}`;
    return fetchData(method, user, property, sortPeriodProperty);
}

/**
 * Función para obtener detalles de medidas específicas.
 * @param {string} method - Método HTTP para el llamado.
 * @param {string} user - Usuario.
 * @param {object} props - Propiedades para el filtrado.
 * @returns {object} - Devuelve un objeto con los detalles de las medidas.
 */
export const getMeasureDetails = (method, user, props) => {
    const property = `${props.IdPeriodoPais}/${props.idEspacio}`;
    try {
        return getObject(method, user, property);   
    } catch (error) {
        console.error("Error fetching measure details:", error);
    }
}