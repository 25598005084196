const attributes = [
    {
        Id: 1,
        Name: "Rental Review",
        Code: "RT"
    },
    {
        Id: 2,
        Name: "Energetic Certificate",
        Code: "EGT"
    },
    {
        Id: 3,
        Name: "Withholding Exemption Certificate",
        Code: "EXT"
    },
]

export default attributes;