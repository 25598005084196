import putObject from '../../../Common/put';

/**
 * Handle the response for measure updates.
 * 
 * @param {Object} response - The response from the server.
 * @returns {Object} An alert object based on the response.
 */
const measuresUpdateResponseHandler = (response) => {
    const isSuccess = response?.status === 200;
    return {
        open: true,
        severity: isSuccess ? 'success' : 'error',
        message: isSuccess ? response.data.mensaje : (response?.data?.mensaje || "Error while saving measures.")
    };
}

/**
 * Business logic responsible for updating measures.
 * Uses the provided response handler function to process the response.
 * 
 * @param {string} method - The request method.
 * @param {string} user - The user making the request.
 * @param {Object} props - Additional properties for the request.
 * @returns {Promise<Object>} A promise that resolves to an alert object.
 */
const updateMeasures = async (method, user, props) => {
    const response = await putObject(method, user, props, "MES");
    return measuresUpdateResponseHandler(response);
}

export default updateMeasures;