import axios from 'axios'
import {REACT_APP_AWS_API} from '../../../config'

//function method for update objects. 
let putObject = async(method, user, props, type) => {
    return new Promise((resolve, reject) => {  
        let uri = REACT_APP_AWS_API + method +  "/" + user.profile + "/" + user.locale; 
        if(type == "MES") {
            uri = REACT_APP_AWS_API + method +  "/" + props.data + "/" + user.profile + "/" + user.locale;
        }
        axios.put(uri, JSON.stringify(props.formulary)).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default putObject