import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_PUT} from '../../../config'

let putSpace = async(form, id, user) => {
    return new Promise((resolve, reject) => {   
        axios.put(REACT_APP_AWS_API_SPACE_PUT + id + "/" + user?.profile + "/" + user?.locale, form)
        .then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default putSpace