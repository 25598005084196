import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_UPLOAD, REACT_APP_AWS_API_MEASURES_UPLOAD} from '../../config'

let uploadspacemasive = (form) => {
    return new Promise((resolve, reject) => { 
        axios.post(REACT_APP_AWS_API_SPACE_UPLOAD, form).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

let uploadmeasuremasive = (form) => {
    return new Promise((resolve, reject) => { 
        axios.post(REACT_APP_AWS_API_MEASURES_UPLOAD, form).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export {
    uploadspacemasive,
    uploadmeasuremasive
}