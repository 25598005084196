import axios from 'axios'
import {REACT_APP_AWS_API_MANAGEMENT_PUT} from '../../../config'

let putUser = async(props) => {
    let formulary={
        Username: props.Email,
        UserAttributes:[{
            Name: "profile",
            Value: props.Rol
        },
        {
            Name: "locale",
            Value: props.Pais
        }]
    }

    return new Promise((resolve, reject) => {   
        axios.put(REACT_APP_AWS_API_MANAGEMENT_PUT, formulary).then(response=> {
            resolve(response.data);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default putUser