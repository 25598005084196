import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#162C5E",
  "&:hover": {
    backgroundColor: "#EDEDED"
  },
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

export default function ButtonHeader(prop) {
  return (
    <WhiteButton startIcon={<AddCircleIcon />} disabled={prop.disable} onClick={prop.event} variant="contained">{prop.name}</WhiteButton>
  );
}