import axios from 'axios'
import {REACT_APP_AWS_API_GET_COUNTRIES} from '../../config'

let getCountries = (user) => {
    return new Promise((resolve, reject) => { 
        let uri = REACT_APP_AWS_API_GET_COUNTRIES + "/" + user?.profile + "/" + user?.locale;
        axios.get(uri).then(response=> {
            resolve(response.data);
        }).catch(error => {
            console.log(error);
            resolve(error);
        });
    })
}

export default getCountries