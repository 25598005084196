import Logo from '../components/menu-logo/logo';
import User from '../components/menu-user/user';

import './navbar-menu.css';

let Navbar = (props) => {
    return(
        <div className="navbar-th">
            <Logo />
            <User user={props?.user}/>
        </div>
    )
}

export default Navbar;