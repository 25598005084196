import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  width: '140px',
  borderRadius: '4px',
  backgroundColor: '#081c42',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#0c2658',
    textTransform: 'none',
  },
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontWeight: 'bold',
  marginLeft: '5px'
}));

export default function ButtonSend(prop) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton endIcon={prop.loading ? <CircularProgress size={12}/> : <SendIcon />} variant="contained" onClick={prop.event} disabled={prop.disabled}>{prop.name}</ColorButton>
    </Stack>
  );
}