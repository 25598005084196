import React, { useState, useEffect  } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useForm, Controller } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Box, Grid, TextField, Select, MenuItem} from "@mui/material";
import InputMask from 'react-input-mask'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '../../../../Components/Alerts/message'

import {getFiltersByCountryId} from '../../../../../Controller/Custom/Space/get'
import post from '../../../../../Controller/Custom/Space/post'
import {getSpaceStatusFile} from '../../../../../Controller/Generic/getstatusfile'

import filtercountries from '../../../Common/countries';
import currencies from '../../../../../Controller/Generic/getCurrencies'
import ButtonCancel from '../../../../Components/Buttons/ButtonCancel'
import ButtonRequest from '../../../../Components/Buttons//ButtonRequest'
import ButtonSend from '../../../../Components/Buttons/ButtonSend'
import ButtonsGroup from '../../../../Components/Buttons/ButtonGroupFiles'
import DecimalTextField from '../../../../Components/Input/DecimalTextField'

import {getRentalReview, getExceptionCertificate, getEnergeticCertificate} from '../../../../../Controller/Custom/Maintenance/get'
import {uploadspacemasive} from '../../../../../Controller/Generic/uploadfile'

import './Modal.css'

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  }

export default function CreateSpace({isOpen, dataRender, cleanComponents, user}) {
    const emailPatter = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const percentage = RegExp(/100$|^100,00$|(^\-?\d{0,3}|^\+?\d{0,3})(\,\d{1,4})? *%?$/);
    const completedate = RegExp(/^((19\d{2})|(20\d{2}))(((02)(0[1-9]|[1-2][0-9]))|(((0(1|[3-9]))|(1[0-2]))(0[1-9]|[1-2][0-9]|30))|((01|03|05|07|08|10|12)(31)))$/);
    const monthyeardate = RegExp(/^((19\d{2})|(20\d{2}))(((0[1-9]|[1-2][0-9]))|(((0(1|[3-9]))|(1[0-2]))))$/);

    const [open, setOpen] = useState(isOpen);
    const [alert, setAlert] = useState({ open: false, severity: '', message: ''});
    const [disable, setDisable] = useState(true);
    const [rentReviewIndex, setRentReviewIndex] = useState();
    const [moduleCountryData, setModuleCountryData] = useState([]);
    const [associateWorkData, setAssociateWorkData] = useState([]);
    const [leassingCompanyData, setLeassingCompanyData] = useState([]);
    const [licenseHolderData, setLicenseHolderData] = useState([]);
    const [contractCurrencyData, setContractCurrencyData] = useState([]);
    const [energeticCertificationData, setEnergeticCertificationData] = useState([]);
    const [withholdingExemtionData, setWithholdingExemtionData] = useState([]);
    const [rentalReviewData, setRentalReviewData] = useState([]);
    const [countrySp, setCountrySp] = useState('')
    const [rentalReview, setRentalReview] = useState('')
    const [rentReviewDisabled, setRentReviewDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [disabledUpload, setDisabledUpload] = useState(false);

  const schema = yup.object().shape({
	term: yup.number("This is a not valid number. Enter a valid number.").typeError('Field term is required.').max(999,"Cannot have more than 3 digits.").required("Field term is required."),
	lack: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull),
	mandatoryComp: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull),
	notice: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull) ,
	phone1: yup.string().max(100,"Cannot have more than 100 digits.").required("Field Phone 1 is required."),
	phone2: yup.string().max(100,"Cannot have more than 100 digits.").min(0).nullable(true).transform(emptyStringToNull) ,
	phone3: yup.string().max(100,"Cannot have more than 100 digits.").min(0).nullable(true).transform(emptyStringToNull) ,
	email1: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').required("Field Email 1 is required."),
	email2: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').min(0).nullable(true).transform(emptyStringToNull) ,
	email3: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').min(0).nullable(true).transform(emptyStringToNull) ,
	landLord: yup.string().max(200,"Cannot have more than 200 digits.").required("LandLord is required."),
	landLordAdress: yup.string().max(200,"Cannot have more than 200 digits.").required("LandLord Adress is required."),
	moduleName: yup.string().max(25,"Cannot have more than 25 digits.").required("Module Name is required."),
	countryCode: yup.string().required("Country Code is required."),
	workCenter: yup.string().required("WorkCenter is required."),
	moduleAdress: yup.string().max(100,"Cannot have more than 100 digits.").required("Module Adress is required."),
	moduleLocation: yup.string().max(50,"Cannot have more than 50 digits.").required("Module Location is required."),
	leasingCompany: yup.string().required("Leasing Company is required."),
	contractCurrency: yup.string().required("Contract Currency is required."),
	rentalView: yup.string().required("Rental Review is required."),
	revIndex: !rentReviewDisabled ? yup.string().matches(percentage, 'Is necesary a valid % number with format xx,xxxx %').required("% revision index is required.") : yup.string().matches(percentage, 'Is necesary a valid % number with format xx.xxxx %'),
	sprIndex:  yup.string().matches(percentage, 'Is necesary a valid % number with format xx,xxxx %').required("Spread over the index is required.") ,
	earlydeparture: yup.string().required("Early Departure is required."),
	energeticCertification: yup.string().required("Energetic certification is required."),
	exemptionCertificate: yup.string().required("Withholding Exemption Certificate is required."),
    procedureType:yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
    extension:yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
    fax: yup.string().min(0).max(100,"Cannot have more than 100 digits.").nullable(true).transform(emptyStringToNull),
    contactChanels: yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
    observations: yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
	parkingIncluded: yup.string().required("Early Departure is required."),
	technicalRooms: yup.string().required("Technical Room is required."),
	generator: yup.string().required("Generator is required."),
	ifrs: yup.string().required("IFRS is required."),
    mandatorydate: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    duedateterm: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    duedateext: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    bankGuaranteeExpirationDate: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    bankGuaranteeRenewalDate:  yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    firstContactStartDate:  yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").required("First Contract Start Date is required."),
    lastDate: yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").required("Start/Renewal Last Date is required."),
    registrationGrant: yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
    monthRent: yup.string().matches(monthyeardate, "Invalid date format. The date must have the following format: AAAAMM").required("Month of rent review is required."),
    completionModuleClosure: yup.string().min(0).matches(monthyeardate, "Invalid date format. The date must have the following format: AAAAMM").nullable(true).transform(emptyStringToNull),
	electricalTransaformationCenter: yup.string().required("Electrical Tranformation Center is required."),
  }).required();

  const {control, handleSubmit, formState: {errors}, register, reset } = useForm({resolver: yupResolver(schema)});
  const [depositball, setDepositball] = useState('');
  const [warranty, setWarranty] = useState('');
  const [endorsementAmount, setEndorsementAmount] = useState('');
  const [isupload, setisUpload] = useState(false);
  const [status, setStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [base64URL, setBase64URL] = useState('');
  const [file, setFile] = useState('');
  const [dataFileStatus, setDataFileStatus] = useState('');

  const handleOnSubmit = async(evt) => {
    evt.countryCode = countrySp;
    setLoading(true)
    setDisabled(true);
    
    let form = {
        Direccion: evt.moduleAdress !== "" ? evt.moduleAdress : null,
        CompaniaArrendamiento: evt.workCenter  !== "" ? evt.workCenter : null,
        FechaInsercion: new Date(),
        UsuarioInsercion: user?.email,
        UsuarioModificacion: user?.email,
        FechaModificacion: new Date(),
        Nombre: evt.moduleName  !== "" ? evt.moduleName : null,
        Ubicacion: evt.moduleLocation  !== "" ? evt.moduleLocation : null,
        IdEmpresa: evt.leasingCompany  !== "" ? evt.leasingCompany: null,
        IdPais: evt.countryCode  !== "" ? evt.countryCode : null,
        AssociatedWorkCenter: evt.workCenter !== "" ? evt.workCenter : null,
        RES_Contrato: {
            FechaComienzo: evt.lastDate !== "" ? evt.lastDate : null,
            FinCumplimientoObligatorio: evt.mandatorydate !== "" ? evt.mandatorydate : null,
            FechaVencimiento: evt.duedateterm !== "" ? evt.duedateterm : null,
            FechaExtension: evt.duedateext !== "" ? evt.duedateext : null,
            Duracion: evt.term,
            TiempoRestante: evt.lack  !== "" ? evt.lack : null,
            PosibleExtension: evt.posibleextension  !== "" ? evt.posibleextension : null,
            CumplimientoObligatorio: evt.mandatoryComp  !== "" ? evt.mandatoryComp : null,
            SalidaAnticipada: evt.earlydeparture !== "" ? evt.earlydeparture  : null,
            Aviso: evt.notice  !== "" ?  evt.notice : null,
            Extension: evt.extension !== "" ? evt.extension : null,
            IFRS: evt.ifrs
        },
        RES_LicenciaActividad: {
            Procedimiento: evt.procedureType  !== "" ? evt.procedureType : null,
            RegistroBeca: evt.registrationGrant  !== "" ? evt.registrationGrant : null,
            Deposito: depositball !== "" ? depositball : null,
            Garantia: warranty  !== "" ? warranty : null,
            ImporteGarantia: endorsementAmount  !== "" ? endorsementAmount : null,
            FechaRenovacionGarantia: evt.bankGuaranteeRenewalDate  !== "" ? evt.bankGuaranteeRenewalDate  : null,
            FechaVencimientoGarantia: evt.bankGuaranteeExpirationDate  !== "" ? evt.bankGuaranteeExpirationDate : null,
            Dueno: evt.landLord  !== "" ?  evt.landLord : null,
            DireccionDueno: evt.landLordAdress  !== "" ? evt.landLordAdress : null,
            Movil1: evt.phone1  !== "" ? evt.phone1 : null,
            Movil2: evt.phone2  !== "" ? evt.phone2 : null,
            Movil3: evt.phone3  !== "" ? evt.phone3 : null,
            Email1: evt.email1  !== "" ? evt.email1 : null,
            Email2: evt.email2  !== "" ? evt.email2 : null,
            Email3: evt.email3  !== "" ? evt.email3 : null,
            Fax: evt.fax  !== "" ? evt.fax : null,
            OtroContacto: evt.contactChanels  !== "" ? evt.contactChanels : null,
            Observaciones: evt.observations  !== "" ? evt.observations : null,
            IdCertificadoEnergetico: evt.energeticCertification  !== "" ? evt.energeticCertification : null,
            IdCertificadoExencionRetencion: evt.exemptionCertificate !== "" ? evt.exemptionCertificate : null,
            IdRRHH_EmpresaSIK: evt.licenseHolder  !== "" ? evt.licenseHolder : null,
        },
        RES_RentaGastos: {
            DistribuidoSobreIndice: evt.sprIndex !== "" ? evt.sprIndex : null,
            RevisionIndice: evt.revIndex !== "" ? evt.revIndex : null,
            RevisionMesAlquiler:  evt.monthRent !== "" ?  evt.monthRent : null,
            FechaPrimerContrato:  evt.firstContactStartDate !== "" ?  evt.firstContactStartDate : null,
            IdIndice: rentReviewIndex !== "" ? rentReviewIndex : null,
            CodigoMonedaFacturacion: evt.contractCurrency !== "" ? evt.contractCurrency : null
        },
        RES_ServiciosEspeciales: {
            CentroTransformacionElectrica: evt.electricalTransaformationCenter !== "" ? evt.electricalTransaformationCenter : null,
            Generador: evt.generator  !== "" ? evt.generator : null,
            SalaTecnica: evt.technicalRooms  !== "" ? evt.technicalRooms : null,
            Parking: evt.parkingIncluded  !== "" ? evt.parkingIncluded : null,
            Otros: evt.Others !== "" ? evt.Others : null,
            FinalizacionCierre:  evt.completionModuleClosure !== "" ?  evt.completionModuleClosure  : null
        }
    }

    let data = await post(form, user);
    if(data.status === 200) {
         setAlert({ open: true, severity: 'success', message: data.data.mensaje});
         setDisabled(false);
         setLoading(false);   
    }else {
        setAlert({ open: true, severity: 'error', message: data.data.mensaje});
        setDisabled(false);
        setLoading(false);
    }

    reset();
    dataRender();
    openModal();
  };

  const changeHandler = (e) => {
    setSelectedFile(e.target.files[0]);
    console.log(e.target.files[0]);

    let file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setBase64URL(result)
        setFile(e.target.files[0])
      })
      .catch(err => {
        console.log(err);
      }); 
  };

  const handleCountry = async(evt) => {
    setCountrySp(evt.target.value);
    let filterresponse = await getFiltersByCountryId(evt.target.value, user);
    setAssociateWorkData(filterresponse.data.mensaje);
    setLicenseHolderData(filterresponse.data.companies);
    setLeassingCompanyData(filterresponse.data.companies);
    setDisable(false);
  }

  const handleRentalReview = async(evt) => {
    let data = evt.target.value.split(':')
    setRentalReview(evt.target.value);
    setRentReviewDisabled(true);
    setRentReviewIndex(data[0]);

    if(data[1] == 'Fixed Rated') {
        setRentReviewDisabled(false);
    }
  }

  const openModal=()=>{
    setCountrySp(null);
    setAssociateWorkData([]);
    setLicenseHolderData([]);
    setLeassingCompanyData([]);
    setDepositball(null);
    setWarranty(null);
    setEndorsementAmount(null);
    reset();
    setOpen(!open);
  }

  const closeAlert=()=>{
    setAlert({ open: false});
  }

  const openupload=()=>{
    setisUpload(!isupload);
  }

  const openstatus=async()=>{
    let datafile = await getSpaceStatusFile(user);
    setDataFileStatus(datafile.data.respuesta);
    setStatus(!status);
  }

  const getBase64 = file => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          console.log("Called", reader);
          baseURL = reader.result;
          console.log(baseURL);
          resolve(baseURL);
        };
        console.log(fileInfo);
      });
  }

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet([]);
        /* custom headers */
        XLSX.utils.sheet_add_aoa(ws, [["Module Name","Module Country","Associated work center","Module Address","Module Town","Leasing Company","Contract Currency","Rental Review (Index)","Spread over the index","% revision fixed rate","Month of Rent Review","First contract starting date","Start/Renewal Date (Last)","Term (months)","Lack (months)","Possible extension (months)","Mandatory Compliance Time (months)","End of Mandatory Compliance date","Due Date Term","Due Date Extension","Early Departure ","Notice (months)","Extension","IFRS","Type of Procedure","Registration / Grant","License Holder","Energetic Certification","Withholding Exemption Certificate ","Bail / Deposit","Warranty","Endorsment Amount","Endorsment Renewal Date","Endorsment Expiration Date","Landlord","Landlord Address","Phone / Contact 1","Phone / Contact 2","Phone / Contact 3","Email / Contact 1","Email / Contact 2","Email / Contact 3","Fax","Other Channels Of Contact","Observations","Electrical Transformation Center","Generator","Technical Rooms ","Parking included","Others","Definitive Module closure"]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, "Space Template.xlsx");
    };

    const handleSubmission = async() => {
        setDisabledUpload(true);
        setLoadingUpload(true);

        const formData = new FormData();

        formData.append('File', selectedFile);

        let formulary = {
            plantilla: "espacio",
            nombreFichero: file.name,
            ficheroCarga: base64URL.split(',')[1]
        }

        let response = await uploadspacemasive(formulary);
        if(response.status === 200) {
            setAlert({ open: true, severity: 'success', message: 'File upload successfully.'});
        } else {
            setAlert({ open: true, severity: 'success', message: 'Error while upload file.'});
        }

        setDisabledUpload(false);
        setLoadingUpload(false);
        
        openupload();
    };

  useEffect(() => {
    (async () => {

        let countryresponse = await filtercountries(user);
        setModuleCountryData(countryresponse);

        let currency = await currencies();
        setContractCurrencyData(currency.data.respuesta);

        let rentalReview = await getRentalReview();
        setRentalReviewData(rentalReview.data.respuesta);

        let WitholdingExemtion = await getExceptionCertificate();
        setWithholdingExemtionData(WitholdingExemtion.data.respuesta);

        let energeticCertification = await getEnergeticCertificate();
        setEnergeticCertificationData(energeticCertification.data.respuesta);

        let datafile = await getSpaceStatusFile();
        setDataFileStatus(datafile.data.respuesta);
    })();
  }, [])

  return ( 
    <div className="containers">
        <ButtonsGroup disable={false}
                clevent={()=>{ cleanComponents() }}
                addevent={()=>{ openModal() }}
                stevent={()=>{ openstatus() }}
                ddevent={()=>{ exportToCSV() }}
                upevent={()=>{ openupload() }}
                name="Add New Space"
                user={user}
        />
        <Alert open={alert.open} severity={alert.severity} message={alert.message} event={closeAlert}/>
        <Modal isOpen={open} style={{width: "100%", maxWidth: "95%"}}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={()=>openModal()}>x</span>
            </ModalHeader>
            <ModalBody>
                <div className="modal-group">
                    <div className='mb-bt15'>
                        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                        <div className="sub-title">
                            <p>Basic Data</p>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="moduleName"
                                defaultValue=""
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register("moduleName")}
                                    fullWidth
                                    variant="filled"
                                    label="Module Name *"
                                />
                                )}
                            />
                            {errors.moduleName && <p style={{color: "red"}}>{errors.moduleName.message}</p>}
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="countryCode"
                                defaultValue=""
                                render={({ field }) => (
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="countryCode">Module Country *</InputLabel>
                                    <Select {...field} fullWidth
                                        labelId="countryCode"
                                        label="Module Country"
                                        {...register("countryCode")}
                                        value={countrySp}
                                        onChange={handleCountry}>
                                            {moduleCountryData.sort((a, b) => a.Nombre > b.Nombre).map((resource, index) => {
                                                 return(
                                                    <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                )}
                            />
                            {errors.countryCode && <p style={{color: "red"}}>{errors.countryCode.message}</p>}
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="workCenter"
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="workCenterLabel">Associate Work Center *</InputLabel>
                                    <Select {...field} fullWidth
                                        labelId="workCenterLabel"
                                        label="Associate Work Center"
                                        {...register("workCenter")}
                                        disabled={disable}>
                                         {associateWorkData.map((resource, index) => {
                                                 return(
                                                    <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                )}
                            />
                            {errors.workCenter && <p style={{color: "red"}}>{errors.workCenter.message}</p>}
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="moduleAdress"
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register("moduleAdress")}
                                    fullWidth
                                    variant="filled"
                                    label="Module Address/Description *"
                                />
                                )}
                            />
                             {errors.moduleAdress && <p style={{color: "red"}}>{errors.moduleAdress.message}</p>}
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="moduleLocation"
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    {...register("moduleLocation")}
                                    variant="filled"
                                    label="Module Town/District *"
                                />
                                )}
                            />
                            <span style={{color: "red"}}>{errors.moduleLocation?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="leasingCompany"
                                defaultValue=""
                                render={({ field }) => (
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="leasingCompanyLabel">Leasing Company *</InputLabel>
                                    <Select  {...field} fullWidth
                                        labelId="leasingCompanyLabel"
                                        label="Leasing Company"
                                        {...register("leasingCompany")}
                                        disabled={disable}>
                                            {leassingCompanyData.map((resource, index) => {
                                                return(
                                                    <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                )}
                            />
                            <span style={{color: "red"}}>{errors.leasingCompany?.message}</span>
                            </Grid>
                        </Grid>
                        <div className="sub-title section" style={{marginTop: "20px"}}>
                            <p>Rent and Associate Expenses</p>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="contractCurrency"
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="contractCurrencyLabel">Contract Currency *</InputLabel>
                                        <Select  {...field} fullWidth
                                            labelId="contractCurrencyLabel"
                                            {...register("contractCurrency")}
                                            label="Contract Currency">
                                            {contractCurrencyData.map((resource, index) => {
                                                 return(
                                                    <MenuItem key={resource.Id} value={resource.Codigo}>{resource.Moneda}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                />
                                    <span style={{color: "red"}}>{errors.contractCurrency?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="rentalView"
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="rentalViewLabel">Rental Review (Index) *</InputLabel>
                                        <Select  {...field} fullWidth
                                            labelId="rentalViewLabel"
                                            {...register("rentalView")}
                                            label="Rental Review (Index) *"
                                            value={rentalReview}
                                            onChange={handleRentalReview}>
                                             {rentalReviewData.map((resource, index) => {
                                                 return(
                                                    <MenuItem key={resource.Id} value={resource.Id+":"+resource.Nombre}>{resource.Nombre}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                />
                                <span style={{color: "red"}}>{errors.rentalView?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="sprIndex"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                    {...field}
                                    {...register("sprIndex")}
                                    fullWidth
                                    variant="filled"
                                    label="Spread over the index *"
                                    />
                                )}
                                />
                                {<span style={{color: "red"}}>{errors.sprIndex?.message}</span>}
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="revIndex"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        {...register("revIndex")}
                                        disabled={rentReviewDisabled}
                                        variant="filled"
                                        label="% revision index if the index selector is fixed rate *"
                                    />
                                )}
                                />
                                {!rentReviewDisabled ? <span style={{color: "red"}}>{errors.revIndex?.message}</span> : ''}
                            </Grid>
                            <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="monthRent"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("monthRent")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMM'
                                                label="Month of Rent Review *"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.monthRent?.message}</span>
                                </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    control={control}
                                    name="firstContactStartDate"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        {...register("firstContactStartDate")}
                                        variant="filled"
                                        multiline
                                        placeholder='Valid format AAAAMMDD'
                                        label="First contract starting date *"
                                    />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.firstContactStartDate?.message}</span>
                            </Grid>
                        </Grid>
                        <div className="sub-title section" style={{marginTop: "20px"}}>
                            <p>Last Contract</p>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="lastDate"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("lastDate")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="Start/Renewal Date (Last) *"
                                        />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.lastDate?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="term"
                                    defaultValue=""
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            {...register("term")}
                                            fullWidth
                                            variant="filled"
                                            label="Term (months) *"
                                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                                        />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.term?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="lack"
                                defaultValue=""
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        {...register("lack")}
                                        maskChar=""
                                        mask="999"
                                        label="Lack (months)"
                                        variant="filled"
                                        fullWidth
                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}>
                                        {(inputProps) => (
                                            <TextField {...inputProps} />
                                        )}
                                    </InputMask>
                                )}
                                />
                                <span style={{color: "red"}}>{errors.lack?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="posibleextension"
                                defaultValue=""
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        maskChar=""
                                        mask="999 + 999"
                                        label="Possible extension (months)"
                                        variant="filled"
                                        fullWidth>
                                    {(inputProps) => (
                                      <TextField {...inputProps} />
                                    )}
                                  </InputMask>
                                )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="mandatoryComp"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("mandatoryComp")}
                                            fullWidth
                                            variant="filled"
                                            label="Mandatory Compliance Time (months)"
                                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                                        />
                                    )}
                                />
                                 <span style={{color: "red"}}>{errors.mandatoryComp?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="mandatorydate"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("mandatorydate")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="End of Mandatory Compliance date"
                                        />
                                    )}   
                                />
                                <span style={{color: "red"}}>{errors.mandatorydate?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="duedateterm"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("duedateterm")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="Due Date Term"
                                        />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.duedateterm?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                        control={control}
                                        name="duedateext"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("duedateext")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Due Date Extension"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.duedateext?.message}</span>                       
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="earlydeparture"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="earlydeparturelabel">Early Departure (Y/N)*</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="earlydeparturelabel"
                                                label="Early Departure (Y/N)"
                                                {...register("earlydeparture")}>
                                                <MenuItem value={'true'}>Yes</MenuItem>
                                                <MenuItem value={'false'}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.earlydeparture?.message}</span>

                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="notice"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                    {...field}
                                    {...register("notice")}
                                    fullWidth
                                    variant="filled"
                                    label="Notice (months)"
                                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()} />
                                )}
                                />
                                 <span style={{color: "red"}}>{errors.notice?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                control={control}
                                name="extension"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                    {...field}
                                    {...register("extension")}
                                    fullWidth
                                    variant="filled"
                                    label="Extension"
                                    />
                                )}
                                />
                                 <span style={{color: "red"}}>{errors.extension?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="ifrs"
                                    defaultValue=""
                                    render={({field}) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="ifrslabel">IFRS *</InputLabel>
                                            <Select {...field} fullWidth
                                                        labelId="ifrslabel"
                                                        label="IFRS"
                                                        {...register("ifrs")}>
                                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                                <MenuItem value={"No"}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.ifrs?.message}</span>
                            </Grid>
                        </Grid>
                        <div className="sub-title section" style={{marginTop: "20px"}}>
                            <p>Activity License</p>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="procedureType"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("procedureType")}
                                            fullWidth
                                            variant="filled"
                                            label="Type of Procedure" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.procedureType?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="registrationGrant"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("registrationGrant")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="Registration / Grant"
                                        />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.registrationGrant?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                            <Controller
                                control={control}
                                name="licenseHolder"
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel id="licenseHolderLabel">License Holder</InputLabel>
                                        <Select  {...field} fullWidth
                                            labelId="licenseHolderLabel"
                                            label="License Holder">
                                            {licenseHolderData.map(resource => {
                                                return(
                                                    <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="energeticCertification"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="energeticCertificationLabel">Energetic Certification *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="energeticCertificationLabel"
                                                {...register("energeticCertification")}
                                                label="Energetic Certification"
                                                >
                                                {energeticCertificationData.map((resource, index) => {
                                                    return(
                                                        <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.energeticCertification?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="exemptionCertificate"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="exemptionCertificateLabel">Withholding Exemption Certificate *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="exemptionCertificateLabel"
                                                label="Withholding Exemption Certificate "
                                                {...register("exemptionCertificate")} >
                                                {withholdingExemtionData.map((resource, index) => {
                                                    return(
                                                        <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.exemptionCertificate?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="ballDeposit"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <DecimalTextField
                                            {...field}
                                            label="Bail//Deposit."
                                            variant="filled"
                                            fullWidth
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            value={depositball}
                                            onChange={(event, value)=> setDepositball(value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="warranty"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <DecimalTextField
                                            {...field}
                                            label="Warranty"
                                            variant="filled"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            fullWidth
                                            value={warranty}
                                            onChange={(event, value)=> setWarranty(value)} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="bankGuaranteeAmount"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <DecimalTextField
                                            {...field}
                                            label="Endorsment Amount"
                                            variant="filled"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            fullWidth
                                            value={endorsementAmount}
                                            onChange={(event, value)=> setEndorsementAmount(value)} 
                                            />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                        control={control}
                                        name="bankGuaranteeRenewalDate"
                                        defaultValue=""
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("bankGuaranteeRenewalDate")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Endorsment Renewal Date"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.bankGuaranteeRenewalDate?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="bankGuaranteeExpirationDate"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("bankGuaranteeExpirationDate")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="Endorsment Expiration Date"
                                        />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.bankGuaranteeExpirationDate?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="landLord"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("landLord")}
                                            variant="filled"
                                            label="LandLord *" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.landLord?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="landLordAdress"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            variant="filled"
                                            {...register("landLordAdress")}
                                            label="LandLord Adress *" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.landLordAdress?.message}</span>
                            </Grid>
                        </Grid>
                        <div className="sub-title section" style={{marginTop: "20px"}}>
                            <p>Contact</p>
                        </div>
                        <Grid container spacing={3}>
                           <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="phone1"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("phone1")}
                                            fullWidth
                                            variant="filled"
                                            label="Phone / Contact 1 *"               
                                        />                 
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.phone1?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="phone2"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("phone2")}
                                            fullWidth
                                            variant="filled"
                                            label="Phone / Contact 2" />
                                    )}
                                />
                                 <span style={{color: "red"}}>{errors.phone2?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="phone3"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("phone3")}
                                            fullWidth
                                            variant="filled"
                                            label="Phone / Contact 3" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.phone3?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="email1"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("email1")}
                                            fullWidth
                                            variant="filled"
                                            label="Email / Contact 1 *" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.email1?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="email2"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("email2")}
                                            fullWidth
                                            variant="filled"
                                            label="Email / Contact 2" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.email2?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="email3"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("email3")}
                                            fullWidth
                                            variant="filled"
                                            label="Email / Contact 3" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.email3?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="fax"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("fax")}
                                            fullWidth
                                            variant="filled"
                                            label="Fax" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.fax?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="contactChanels"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("contactChanels")}
                                            variant="filled"
                                            label="Other Channels Of Contact" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.contactChanels?.message}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="observations"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("observations")}
                                            fullWidth
                                            variant="filled"
                                            label="Observations" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.observations?.message}</span>
                            </Grid>
                        </Grid>
                        <div className="sub-title section" style={{marginTop: "20px"}}>
                            <p>Special Endowment</p>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="electricalTransaformationCenter"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="electricalTransaformationCenterLabel">Electrical Transformation Center *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="electricalTransaformationCenterLabel"
                                                {...register("electricalTransaformationCenter")}
                                                label="Electrical Transformation Center"
                                                >
                                                <MenuItem value={"true"}>Yes</MenuItem>
                                                <MenuItem value={"false"}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.electricalTransaformationCenter?.message}</span>
                               </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="generator"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="generatorLabel">Generator *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="generatorLabel"
                                                {...register("generator")}
                                                label="Generator"
                                                >
                                                <MenuItem value={"true"}>Yes</MenuItem>
                                                <MenuItem value={"false"}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.generator?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="technicalRooms"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="technicalRoomsLabel">Technical Rooms *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="technicalRoomsLabel"
                                                {...register("technicalRooms")}
                                                label="Technical Rooms"
                                                >
                                                <MenuItem value={'true'}>Yes</MenuItem>
                                                <MenuItem value={'false'}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.technicalRooms?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="parkingIncluded"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="parkingIncludedLabel">Parking included *</InputLabel>
                                            <Select  {...field} fullWidth
                                                labelId="parkingIncludedLabel"
                                                label="Parking included"
                                                {...register("parkingIncluded")}
                                                >
                                                <MenuItem value={"yes"}>Yes</MenuItem>
                                                <MenuItem value={"no"}>No</MenuItem>
                                                <MenuItem value={'Only Parking'}>Only Parking</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    />
                                    <span style={{color: "red"}}>{errors.parkingIncluded?.message}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                        control={control}
                                        name="Others"
                                        defaultValue=""
                                        render={({ field }) => (
                                    
                                    <FormControl variant="filled" fullWidth>
                                            <InputLabel id="othersLabel">Others</InputLabel>
                                            <Select  {...field} fullWidth
                                                label="Others" >
                                                <MenuItem value={"Living place"}>Living place</MenuItem>
                                                <MenuItem value={"Local RLT"}>Local RLT</MenuItem>
                                                <MenuItem value={'Client site'}>Client site</MenuItem>
                                                <MenuItem value={'Others'}>Others</MenuItem>
                                            </Select>
                                        </FormControl>  
                                        )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="completionModuleClosure"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("completionModuleClosure")}
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMM'
                                            label="Completion/definitive Module closure" />
                                    )}
                                />
                                <span style={{color: "red"}}>{errors.completionModuleClosure?.message}</span>
                            </Grid>
                        </Grid>
                        <div className='section section-button mt-20'>
                            <div className='section-button-save'>
                                <ButtonRequest name="Save Request" disabled={disabled} loading={loading} />
                            </div>
                        </div>
                        </Box>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        <Modal isOpen={isupload}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={()=> openupload()}>x</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <label for="file" className="drop-container">
                        <span className="drop-title">Drop files here</span>
                        or
                        <input type="file" id="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required onChange={changeHandler}/>
                    </label>
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonSend event={() => handleSubmission()} disabled={disabledUpload} loading={loadingUpload} name="Upload" />
                <ButtonCancel event={() => openupload()} name="Cancel" />
            </ModalFooter>
        </Modal>
        <Modal isOpen={status} style={{width: "100%", maxWidth: "50%"}}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={()=> openstatus()}>x</span>
            </ModalHeader>
            <ModalBody>
                <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th key={1}> File Name</th>
                            <th key={2}> Total Rows</th>
                            <th key={3}> Row Charged</th>
                            <th key={4}> Status</th>
                            <th key={5}> Date</th>
                            <th key={6}> Message</th>
                        </tr>
                    </thead>
                    <tbody>	
                        <tr key={1}>
                            <td>{dataFileStatus?.NombreFichero}</td>
                            <td>{dataFileStatus?.FilasTotales}</td>
                            <td>{dataFileStatus?.FilasCargadas}</td>
                            <td>{dataFileStatus?.Estado}</td>
                            <td>{dataFileStatus?.Fecha}</td>
                            <td>{dataFileStatus?.Mensaje}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonCancel event={() => openstatus()} name="Cancel" />
            </ModalFooter>
        </Modal>
    </div>
  );
}
