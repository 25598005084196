import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Box, Grid, MenuItem, Select, TextField} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import {Controller, useForm} from "react-hook-form";
import InputMask from 'react-input-mask'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DecimalTextField from "../../../../Components/Input/DecimalTextField.js";
import ButtonRequest from "../../../../Components/Buttons/ButtonRequest.js";
import CsLoader from '../../../../Components/Loading/Loader.js';
import React, {useState, useEffect} from "react";
import {getFiltersByCountryId} from "../../../../../Controller/Custom/Space/get.js";
import put from "../../../../../Controller/Custom/Space/put.js";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import filtercountries from '../../../Common/countries.js';
import currencies from "../../../../../Controller/Generic/getCurrencies.js";
import {
    getEnergeticCertificate,
    getExceptionCertificate,
    getRentalReview
} from "../../../../../Controller/Custom/Maintenance/get.js";


const emailPatter = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const percentage = RegExp(/100$|^100,00$|(^\-?\d{0,3}|^\+?\d{0,3})(\,\d{1,4})? *%?$/);
const completedate = RegExp(/^((19\d{2})|(20\d{2}))(((02)(0[1-9]|[1-2][0-9]))|(((0(1|[3-9]))|(1[0-2]))(0[1-9]|[1-2][0-9]|30))|((01|03|05|07|08|10|12)(31)))$/);
const monthyeardate = RegExp(/^((19\d{2})|(20\d{2}))(((0[1-9]|[1-2][0-9]))|(((0(1|[3-9]))|(1[0-2]))))$/);

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

export const UpdateSpaceModal = ({data, dataRender, user, alert, setAlert, openedModal, setOpenedModal}) => {
    const [disable, setDisable] = useState(true);
    const [moduleCountryData, setModuleCountryData] = useState([]);
    const [associateWorkData, setAssociateWorkData] = useState([]);
    const [leassingCompanyData, setLeassingCompanyData] = useState([]);
    const [contractCurrencyData, setContractCurrencyData] = useState([]);
    const [energeticCertificationData, setEnergeticCertificationData] = useState([]);
    const [withholdingExemtionData, setWithholdingExemtionData] = useState([]);
    const [rentalReviewData, setRentalReviewData] = useState([]);
    const [licenseHolderData, setLicenseHolderData] = useState([]);
    const [rentalReview, setRentalReview] = useState('')
    const [countrySp, setCountrySp] = useState('')
    const [leasingCompany, setLeassingCompany] = useState('')
    const [workcenter, setWorkCenter] = useState('')
    const [disabled, setDisabled] = useState(false);
    const [rentReviewDisabled, setRentReviewDisabled] = useState(true);
    const [rentReviewIndex, setRentReviewIndex] = useState();
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(true);
    const [depositball, setDepositball] = useState('');
    const [warranty, setWarranty] = useState('');
    const [endorsementAmount, setEndorsementAmount] = useState('');
    const schema = yup.object().shape({
        term: yup.number("This is a not valid number. Enter a valid number.").max(999,"Cannot have more than 3 digits.").typeError('Field term is required.').required("Field term is required."),
        lack: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull),
        mandatoryComp: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull),
        notice: yup.number().min(0).max(999, "Cannot have more than 3 digits.").nullable(true).transform(emptyStringToNull) ,
        phone1: yup.string().max(100,"Cannot have more than 100 digits.").required("Field Phone 1 is required."),
        phone2: yup.string().max(100,"Cannot have more than 100 digits.").min(0).nullable(true).transform(emptyStringToNull) ,
        phone3: yup.string().max(100,"Cannot have more than 100 digits.").min(0).nullable(true).transform(emptyStringToNull) ,
        email1: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').required("Field Email 1 is required."),
        email2: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').min(0).nullable(true).transform(emptyStringToNull) ,
        email3: yup.string().max(100,"Cannot have more than 100 digits.").matches(emailPatter, 'Is necesary a valid email. Must contain @.').min(0).nullable(true).transform(emptyStringToNull) ,
        landLord: yup.string().max(200,"Cannot have more than 200 digits.").required("LandLord is required."),
        landLordAdress: yup.string().max(200,"Cannot have more than 200 digits.").required("LandLord Adress is required."),
        moduleName: yup.string().max(25,"Cannot have more than 25 digits.").required("Module Name is required."),
        countryCode: yup.string().required("Country Code is required."),
        workCenter: yup.string().required("WorkCenter is required."),
        moduleAdress: yup.string().max(100,"Cannot have more than 100 digits.").required("Module Adress is required."),
        moduleLocation: yup.string().max(50,"Cannot have more than 50 digits.").required("Module Location is required."),
        leasingCompany: yup.string().required("Leasing Company is required."),
        contractCurrency: yup.string().required("Contract Currency is required."),
        rentalView: yup.string().required("Rental Review is required."),
        revIndex: !rentReviewDisabled ? yup.string().matches(percentage, 'Is necesary a valid % number with format xx,xxxx %').required("% revision index is required.") : yup.string().min(0).matches(percentage, 'Is necesary a valid % number with format xx.xxxx %').nullable(true).transform(emptyStringToNull),
        sprIndex:  yup.string().matches(percentage, 'Is necesary a valid % number with format xx,xxxx %').required("Spread over the index is required.") ,
        earlydeparture: yup.string().required("Early Departure is required."),
        energeticCertification: yup.string().required("Energetic certification is required."),
        exemptionCertificate: yup.string().required("Withholding Exemption Certificate is required."),
        procedureType:yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
        extension:yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
        fax: yup.string().min(0).max(100,"Cannot have more than 100 digits.").nullable(true).transform(emptyStringToNull),
        contactChanels: yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
        observations: yup.string().min(0).max(200,"Cannot have more than 200 digits.").nullable(true).transform(emptyStringToNull),
        parkingIncluded: yup.string().required("Early Departure is required."),
        technicalRooms: yup.string().required("Technical Room is required."),
        generator: yup.string().required("Generator is required."),
        ifrs: yup.string().required("IFRS is required."),
        mandatorydate: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        duedateterm: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        duedateext: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        bankGuaranteeExpirationDate: yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        bankGuaranteeRenewalDate:  yup.string().min(0).matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        firstContactStartDate:  yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").required("First Contract Start Date is required."),
        lastDate: yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").required("Start/Renewal Last Date is required."),
        registrationGrant: yup.string().matches(completedate, "Invalid date format. The date must have the following format: AAAAMMDD").nullable(true).transform(emptyStringToNull),
        monthRent: yup.string().matches(monthyeardate, "Invalid date format. The date must have the following format: AAAAMM").required("Month of rent review is required."),
        completionModuleClosure: yup.string().min(0).matches(monthyeardate, "Invalid date format. The date must have the following format: AAAAMM").nullable(true).transform(emptyStringToNull),
        electricalTransaformationCenter: yup.string().required("Electrical Tranformation Center is required."),
      }).required();

    const {control, handleSubmit, formState: {errors}, register} = useForm({resolver: yupResolver(schema)});

    const handleOnSubmit = async (evt) => {
        (async () => {
            evt.countryCode = countrySp;
            setLoading(true);
            setDisabled(true);

            let form = {
                Direccion: evt.moduleAdress !== "" ? evt.moduleAdress : null,
                CompaniaArrendamiento: evt.workCenter !== "" ? evt.workCenter : null,
                UsuarioModificacion: user?.email,
                FechaModificacion: new Date(),
                Nombre: evt.moduleName !== "" ? evt.moduleName : null,
                Ubicacion: evt.moduleLocation !== "" ? evt.moduleLocation : null,
                IdEmpresa: evt.leasingCompany !== "" ? evt.leasingCompany : null,
                IdPais: evt.countryCode !== "" ? evt.countryCode : null,
                AssociatedWorkCenter: evt.workCenter !== "" ? evt.workCenter : null,
                RES_Contrato: {
                    FechaComienzo: evt.lastDate !== "" ? evt.lastDate : null,
                    FinCumplimientoObligatorio: evt.mandatorydate !== "" ? evt.mandatorydate : null,
                    FechaVencimiento: evt.duedateterm !== "" ? evt.duedateterm : null,
                    FechaExtension: evt.duedateext !== "" ? evt.duedateext : null,
                    Duracion: evt.term,
                    TiempoRestante: evt.lack !== "" ? evt.lack : null,
                    PosibleExtension: evt.posibleextension !== "" ? evt.posibleextension : null,
                    CumplimientoObligatorio: evt.mandatoryComp !== "" ? evt.mandatoryComp : null,
                    SalidaAnticipada: evt.earlydeparture !== "" ? evt.earlydeparture : null,
                    Aviso: evt.notice !== "" ? evt.notice : null,
                    Extension: evt.extension !== "" ? evt.extension : null,
                    IFRS: evt.ifrs
                },
                RES_LicenciaActividad: {
                    Procedimiento: evt.procedureType  !== "" ? evt.procedureType : null,
                    RegistroBeca: evt.registrationGrant  !== "" ? evt.registrationGrant : null,
                    Deposito: depositball !== "" ? depositball : null,
                    Garantia: warranty  !== "" ? warranty : null,
                    ImporteGarantia: endorsementAmount  !== "" ? endorsementAmount : null,
                    FechaRenovacionGarantia: evt.bankGuaranteeRenewalDate  !== "" ? evt.bankGuaranteeRenewalDate  : null,
                    FechaVencimientoGarantia: evt.bankGuaranteeExpirationDate  !== "" ? evt.bankGuaranteeExpirationDate : null,
                    Dueno: evt.landLord  !== "" ?  evt.landLord : null,
                    DireccionDueno: evt.landLordAdress  !== "" ? evt.landLordAdress : null,
                    Movil1: evt.phone1  !== "" ? evt.phone1 : null,
                    Movil2: evt.phone2  !== "" ? evt.phone2 : null,
                    Movil3: evt.phone3  !== "" ? evt.phone3 : null,
                    Email1: evt.email1  !== "" ? evt.email1 : null,
                    Email2: evt.email2  !== "" ? evt.email2 : null,
                    Email3: evt.email3  !== "" ? evt.email3 : null,
                    Fax: evt.fax  !== "" ? evt.fax : null,
                    OtroContacto: evt.contactChanels  !== "" ? evt.contactChanels : null,
                    Observaciones: evt.observations  !== "" ? evt.observations : null,
                    IdCertificadoEnergetico: evt.energeticCertification  !== "" ? evt.energeticCertification : null,
                    IdCertificadoExencionRetencion: evt.exemptionCertificate !== "" ? evt.exemptionCertificate : null,
                    IdRRHH_EmpresaSIK: evt.licenseHolder  !== "" ? evt.licenseHolder : null,
                },
                RES_RentaGastos: {
                    DistribuidoSobreIndice: evt.sprIndex !== "" ? evt.sprIndex : null,
                    RevisionIndice: evt.revIndex !== "" ? evt.revIndex : null,
                    RevisionMesAlquiler:  evt.monthRent !== "" ?  evt.monthRent : null,
                    FechaPrimerContrato:  evt.firstContactStartDate !== "" ?  evt.firstContactStartDate : null,
                    IdIndice: rentReviewIndex !== "" ? rentReviewIndex : null,
                    CodigoMonedaFacturacion: evt.contractCurrency !== "" ? evt.contractCurrency : null
                },
                RES_ServiciosEspeciales: {
                    CentroTransformacionElectrica: evt.electricalTransaformationCenter !== "" ? evt.electricalTransaformationCenter : null,
                    Generador: evt.generator  !== "" ? evt.generator : null,
                    SalaTecnica: evt.technicalRooms  !== "" ? evt.technicalRooms : null,
                    Parking: evt.parkingIncluded  !== "" ? evt.parkingIncluded : null,
                    Otros: evt.Others !== "" ? evt.Others : null,
                    FinalizacionCierre:  evt.completionModuleClosure !== "" ?  evt.completionModuleClosure  : null
                }
            }

            let response = await put(form, data.Id, user);
            if (response.status === 200) {
                await dataRender();
                setAlert({open: true, severity: 'success', message: response.data.mensaje});
                setDisabled(false);
                setLoading(false);
                setOpenedModal(false);
            } else {
                setAlert({open: true, severity: 'error', message: response.data.mensaje});
                setDisabled(false);
                setLoading(false);
            }
        })();
    };

    const handleCountry = async (evt) => {
        setCountrySp(evt.target.value);
        let filterresponse = await getFiltersByCountryId(evt.target.value, user);
        setAssociateWorkData(filterresponse.data.respuesta);
        setLicenseHolderData(filterresponse.data.respuesta);
        setLeassingCompanyData(filterresponse.data.companies);
        setSelectLoading(!selectLoading);
        setDisable(false);
    }

    const handleWorkCenter = async (evt) => {
        setWorkCenter(evt.target.value);
    }

    const handleLeasingCompany = async (evt) => {
        setLeassingCompany(evt.target.value);
    }

    const handleRentalReview = async (evt) => {
        let data = evt.target.value.split(':')
        setRentalReview(evt.target.value);
        setRentReviewDisabled(true);
        setRentReviewIndex(data[0]);

        if (data[1] == 'Fixed Rated') {
            setRentReviewDisabled(false);
        }
    }
    
    useEffect(() => {
        (async () => {
                setRentReviewDisabled(true);
                if (data.RES_RentaGastos?.RES_Indice?.Nombre == 'Fixed Rated') {
                    setRentReviewDisabled(false);
                }

                //set default data when open popup
                setCountrySp(data.IdPais);
                setLeassingCompany(data.IdEmpresa);
                setWorkCenter(data.AssociatedWorkCenter);
                setRentalReview(data.RES_RentaGastos?.RES_Indice?.Id + ":" + data.RES_RentaGastos?.RES_Indice?.Nombre);
                setDepositball(data.RES_LicenciaActividad?.Deposito);
                setWarranty(data.RES_LicenciaActividad?.Garantia);
                setEndorsementAmount(data.RES_LicenciaActividad?.ImporteGarantia);

                let countryresponse = await filtercountries(user);
                setModuleCountryData(countryresponse);

                let currency = await currencies();
                setContractCurrencyData(currency.data.respuesta);

                let rentalReview = await getRentalReview();
                setRentalReviewData(rentalReview.data.respuesta);

                let WitholdingExemtion = await getExceptionCertificate();
                setWithholdingExemtionData(WitholdingExemtion.data.respuesta);

                let energeticCertification = await getEnergeticCertificate();
                setEnergeticCertificationData(energeticCertification.data.respuesta);

                let response = await getFiltersByCountryId(data.IdPais, user);
                setLicenseHolderData(response.data.companies);
                setAssociateWorkData(response.data.mensaje);
                setLeassingCompanyData(response.data.companies);
                setSelectLoading(!selectLoading);
        })();
    }, []);
    return <>
        <Modal isOpen={openedModal} style={{width: "100%", maxWidth: "95%"}}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={() => setOpenedModal(false)}>x</span>
            </ModalHeader>
            <ModalBody>
            {!selectLoading ? 
                <div className="modal-group">
                    
                    <div className='mb-bt15'>
                        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                            <div className="sub-title">
                                <p>Basic Data</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="moduleName"
                                        defaultValue={data.Nombre}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("moduleName")}
                                                fullWidth
                                                variant="filled"
                                                label="Module Name *"
                                            />
                                        )}
                                    />
                                    {errors.moduleName && <p style={{color: "red"}}>{errors.moduleName?.message}</p>}

                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="countryCode"
                                        defaultValue={data.IdPais}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="countryCode">Module Country *</InputLabel>
                                                <Select {...field} fullWidth
                                                        labelId="countryCode"
                                                        label="Module Country"
                                                        {...register("countryCode")}
                                                        value={countrySp}
                                                        onChange={handleCountry}>
                                                    {moduleCountryData.sort((a, b) => a.Nombre > b.Nombre).map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id}
                                                                      value={resource.Id}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.countryCode?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="workCenter"
                                        rules={{required: true}}
                                        defaultValue={data.AssociatedWorkCenter}
                                        render={({field, onChange}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="workCenterLabel">Associate Work Center
                                                    </InputLabel>
                                                <Select {...field} fullWidth
                                                        labelId="workCenterLabel"
                                                        label="Associate Work Center"
                                                        {...register("workCenter")}
                                                        value={workcenter}
                                                        onChange={handleWorkCenter}
                                                        isLoading={selectLoading}
                                                >       
                                                    {associateWorkData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id}
                                                                      value={resource.Id}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.workCenter?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="moduleAdress"
                                        defaultValue={data.Direccion}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("moduleAdress")}
                                                fullWidth
                                                variant="filled"
                                                label="Module Address/Description *"
                                            />
                                        )}
                                    />
                                    {errors.moduleAdress && <p style={{color: "red"}}>{errors.moduleAdress.message}</p>}
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="moduleLocation"
                                        defaultValue={data.Ubicacion}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("moduleLocation")}
                                                variant="filled"
                                                label="Module Town/District *"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.moduleLocation?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="leasingCompany"
                                        defaultValue={data.RRHH_EmpresaSIK?.Id}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="leasingCompanyLabel">Leasing Company *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="leasingCompanyLabel"
                                                         label="Leasing Company"
                                                         {...register("leasingCompany")}
                                                         value={leasingCompany}
                                                         onChange={handleLeasingCompany}>
                                                    {leassingCompanyData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.leasingCompany?.message}</span>
                                </Grid>
                            </Grid>
                            <div className="sub-title section" style={{marginTop: "20px"}}>
                                <p>Rent and Associate Expenses</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="contractCurrency"
                                        defaultValue={data.RES_RentaGastos?.CodigoMonedaFacturacion}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="contractCurrencyLabel">Contract Currency
                                                    *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="contractCurrencyLabel"
                                                         {...register("contractCurrency")}
                                                        label="Contract Currency">
                                                    {contractCurrencyData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Codigo}
                                                                      value={resource.Codigo}>{resource.Moneda}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.contractCurrency?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="rentalView"
                                        defaultValue={String(data.RES_RentaGastos?.RES_Indice?.Id + ":" + data.RES_RentaGastos?.RES_Indice?.Nombre)}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="rentalViewLabel">Rental Review (Index) *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="rentalViewLabel"
                                                         {...register("rentalView")}
                                                         label="Rental Review (Index)*"
                                                         value={rentalReview}
                                                         onChange={handleRentalReview}>
                                                    {rentalReviewData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id}
                                                                      value={resource.Id + ":" + resource.Nombre}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.rentalView?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="sprIndex"
                                        defaultValue={data.RES_RentaGastos?.DistribuidoSobreIndice}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("sprIndex")}
                                                fullWidth
                                                variant="filled"
                                                label="Spread over the index *"
                                            />
                                        )}
                                    />
                                    {<span style={{color: "red"}}>{errors.sprIndex?.message}</span>}
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="revIndex"
                                        defaultValue={data.RES_RentaGastos?.RevisionIndice}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("revIndex")}
                                                disabled={rentReviewDisabled}
                                                variant="filled"
                                                label="% revision index if the index selector is fixed rate *"
                                            />
                                        )}
                                    />
                                    {!rentReviewDisabled ?
                                        <span style={{color: "red"}}>{errors.revIndex?.message}</span> : ''}
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                            control={control}
                                            name="monthRent"
                                            defaultValue={data.RES_RentaGastos?.RevisionMesAlquiler}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    {...register("monthRent")}
                                                    variant="filled"
                                                    multiline
                                                    placeholder='Valid format AAAAMM'
                                                    label="Month of Rent Review *"
                                                />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.monthRent?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="firstContactStartDate"
                                        defaultValue={data.RES_RentaGastos?.FechaPrimerContrato}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            {...register("firstContactStartDate")}
                                            variant="filled"
                                            multiline
                                            placeholder='Valid format AAAAMMDD'
                                            label="First contract starting date *"
                                        />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.firstContactStartDate?.message}</span>
                                </Grid>
                            </Grid>
                            <div className="sub-title section" style={{marginTop: "20px"}}>
                                <p>Last Contract</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="lastDate"
                                        defaultValue={data.RES_Contrato?.FechaComienzo}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("lastDate")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Start/Renewal Date (Last) *"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.lastDate?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="term"
                                        defaultValue={data.RES_Contrato?.Duracion}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("term")}
                                                fullWidth
                                                variant="filled"
                                                label="Term (months) *"
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.term?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="lack"
                                        defaultValue={data.RES_Contrato?.TiempoRestante}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("lack")}
                                                fullWidth
                                                variant="filled"
                                                label="Lack (months)"
                                                onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.lack?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="posibleextension"
                                        defaultValue={data.RES_Contrato?.PosibleExtension}
                                        render={({field}) => (
                                            <InputMask
                                                {...field}
                                                maskChar=""
                                                mask="999 + 999"
                                                label="Possible extension (months)"
                                                variant="filled"
                                                fullWidth>
                                                    {(inputProps) => (
                                                        <TextField {...inputProps} />
                                                    )}
                                            </InputMask>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="mandatoryComp"
                                        defaultValue={data.RES_Contrato?.CumplimientoObligatorio}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("mandatoryComp")}
                                                fullWidth
                                                variant="filled"
                                                label="Mandatory Compliance Time (months)"
                                                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.mandatoryComp?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="mandatorydate"
                                        defaultValue={data.RES_Contrato?.FinCumplimientoObligatorio}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("mandatorydate")}
                                                variant="filled"
                                                label="End of Mandatory Compliance date"
                                            />
                                        )}   
                                    />
                                    <span style={{color: "red"}}>{errors.mandatorydate?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="duedateterm"
                                        defaultValue={data.RES_Contrato?.FechaVencimiento}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("duedateterm")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Due Date Term"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.duedateterm?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                            control={control}
                                            name="duedateext"
                                            defaultValue={data.RES_Contrato?.FechaExtension}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    {...register("duedateext")}
                                                    variant="filled"
                                                    multiline
                                                    placeholder='Valid format AAAAMMDD'
                                                    label="Due Date Extension"
                                                />
                                            )}
                                        />
                                        <span style={{color: "red"}}>{errors.duedateext?.message}</span>                       
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="earlydeparture"
                                        defaultValue={data.RES_Contrato?.SalidaAnticipada}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="earlydeparturelabel">Early Departure (Y/N)</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="earlydeparturelabel"
                                                         label="Early Departure (Y/N)"
                                                         {...register("earlydeparture")}>
                                                    <MenuItem value={true}>Yes</MenuItem>
                                                    <MenuItem value={false}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.earlydeparture?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="notice"
                                        defaultValue={data.RES_Contrato?.Aviso}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("notice")}
                                                fullWidth
                                                variant="filled"
                                                label="Notice (months)"
                                                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.notice?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="extension"
                                        defaultValue={data.RES_Contrato?.Extension}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("extension")}
                                                fullWidth
                                                variant="filled"
                                                label="Extension"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.extension?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="ifrs"
                                        defaultValue={data.RES_Contrato?.IFRS}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="ifrslabel">IFRS *</InputLabel>
                                                <Select  {...field} fullWidth
                                                        labelId="ifrslabel"
                                                        label="IFRS"
                                                        {...register("ifrs")}>
                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                    <MenuItem value={"No"}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.ifrs?.message}</span>
                                </Grid>
                            </Grid>
                            <div className="sub-title section" style={{marginTop: "20px"}}>
                                <p>Activity License</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="procedureType"
                                        defaultValue={data.RES_LicenciaActividad?.Procedimiento}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("procedureType")}
                                                fullWidth
                                                variant="filled"
                                                label="Type of Procedure"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.procedureType?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="registrationGrant"
                                        defaultValue={data.RES_LicenciaActividad?.RegistroBeca}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("registrationGrant")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Registration / Grant"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.registrationGrant?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="licenseHolder"
                                        defaultValue={data.RES_LicenciaActividad?.IdRRHH_EmpresaSIK}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="licenseHolderLabel">License Holder</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="licenseHolderLabel"
                                                         label="License Holder">
                                                    {licenseHolderData.map(resource => {
                                                        return (
                                                            <MenuItem key={resource.Id} value={resource.Id}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="energeticCertification"
                                        defaultValue={data.RES_LicenciaActividad?.IdCertificadoEnergetico}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="energeticCertificationLabel">Energetic Certification
                                                    *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="energeticCertificationLabel"
                                                         label="Energetic Certification"
                                                         {...register("energeticCertification")}
                                                >
                                                    {energeticCertificationData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id}
                                                                      value={resource.Id}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.energeticCertification?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="exemptionCertificate"
                                        defaultValue={data.RES_LicenciaActividad?.IdCertificadoExencionRetencion}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="exemptionCertificateLabel">Withholding Exemption
                                                    Certificate *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="exemptionCertificateLabel"
                                                         label="Withholding Exemption Certificate *"
                                                         {...register("exemptionCertificate")}
                                                >
                                                    {withholdingExemtionData.map((resource, index) => {
                                                        return (
                                                            <MenuItem key={resource.Id}
                                                                      value={resource.Id}>{resource.Nombre}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.exemptionCertificate?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="ballDeposit"
                                        defaultValue={data.RES_LicenciaActividad?.Deposito}
                                        render={({field}) => (
                                            <DecimalTextField
                                                {...field}
                                                label="Bail//Deposit."
                                                variant="filled"
                                                fullWidth
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                value={depositball}
                                                onChange={(event, value) => setDepositball(value)}/>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="warranty"
                                        defaultValue={data.RES_LicenciaActividad?.Garantia}
                                        render={({field}) => (
                                            <DecimalTextField
                                                {...field}
                                                label="Warranty"
                                                variant="filled"
                                                fullWidth
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                value={warranty}
                                                onChange={(event, value) => setWarranty(value)}/>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="bankGuaranteeAmount"
                                        defaultValue={data.RES_LicenciaActividad?.ImporteGarantia}
                                        render={({field}) => (
                                            <DecimalTextField
                                                {...field}
                                                label="Endorsment Amount"
                                                variant="filled"
                                                fullWidth
                                                decimalCharacter=","
                                                digitGroupSeparator="."
                                                value={endorsementAmount}
                                                onChange={(event, value) => setEndorsementAmount(value)}/>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                            control={control}
                                            name="bankGuaranteeRenewalDate"
                                            defaultValue={data.RES_LicenciaActividad?.FechaRenovacionGarantia}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    {...register("bankGuaranteeRenewalDate")}
                                                    variant="filled"
                                                    multiline
                                                    placeholder='Valid format AAAAMMDD'
                                                    label="Endorsment Renewal Date"
                                                />
                                            )}
                                        />
                                        <span style={{color: "red"}}>{errors.bankGuaranteeRenewalDate?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="bankGuaranteeExpirationDate"
                                        defaultValue={data.RES_LicenciaActividad?.FechaVencimientoGarantia}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("bankGuaranteeExpirationDate")}
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMMDD'
                                                label="Endorsment Expiration Date"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.bankGuaranteeExpirationDate?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="landLord"
                                        defaultValue={data.RES_LicenciaActividad?.Dueno}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                {...register("landLord")}
                                                variant="filled"
                                                label="LandLord *"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.landLord?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="landLordAdress"
                                        defaultValue={data.RES_LicenciaActividad?.DireccionDueno}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="filled"
                                                {...register("landLordAdress")}
                                                label="LandLord Adress *"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.landLordAdress?.message}</span>
                                </Grid>
                            </Grid>
                            <div className="sub-title section" style={{marginTop: "20px"}}>
                                <p>Contact</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="phone1"
                                        defaultValue={data.RES_LicenciaActividad?.Movil1}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("phone1")}
                                                fullWidth
                                                variant="filled"
                                                label="Phone / Contact 1"
                                            />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.phone1?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="phone2"
                                        defaultValue={data.RES_LicenciaActividad?.Movil2}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("phone2")}
                                                fullWidth
                                                variant="filled"
                                                label="Phone / Contact 2"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.phone2?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="phone3"
                                        defaultValue={data.RES_LicenciaActividad?.Movil3}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("phone3")}
                                                fullWidth
                                                variant="filled"
                                                label="Phone / Contact 3"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.phone3?.message}</span>

                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="email1"
                                        defaultValue={data.RES_LicenciaActividad?.Email1}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("email1")}
                                                fullWidth
                                                variant="filled"
                                                label="Email / Contact 1 *"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.email1?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="email2"
                                        defaultValue={data.RES_LicenciaActividad?.Email2}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("email2")}
                                                fullWidth
                                                variant="filled"
                                                label="Email / Contact 2"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.email2?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="email3"
                                        defaultValue={data.RES_LicenciaActividad?.Email3}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("email3")}
                                                fullWidth
                                                variant="filled"
                                                label="Email / Contact 3"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.email3?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="fax"
                                        defaultValue={data.RES_LicenciaActividad?.Fax}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("fax")}
                                                fullWidth
                                                variant="filled"
                                                label="Fax"/>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="contactChanels"
                                        defaultValue={data.RES_LicenciaActividad?.OtroContacto}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                variant="filled"
                                                label="Other Channels Of Contact"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.fax?.message}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="observations"
                                        defaultValue={data.RES_LicenciaActividad?.Observaciones}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                {...register("observations")}
                                                fullWidth
                                                variant="filled"
                                                label="Observations"/>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.observations?.message}</span>
                                </Grid>
                            </Grid>
                            <div className="sub-title section" style={{marginTop: "20px"}}>
                                <p>Special Endowment</p>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="electricalTransaformationCenter"
                                        defaultValue={String(data.RES_ServiciosEspeciales?.CentroTransformacionElectrica)}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="electricalTransaformationCenterLabel">Electrical
                                                    Transformation Center *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="electricalTransaformationCenterLabel"
                                                         {...register("electricalTransaformationCenter")}
                                                         label="Electrical Transformation Center"
                                                >
                                                    <MenuItem value={"true"}>Yes</MenuItem>
                                                    <MenuItem value={"false"}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span
                                        style={{color: "red"}}>{errors.electricalTransaformationCenter?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="generator"
                                        defaultValue={String(data.RES_ServiciosEspeciales?.Generador)}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="generatorLabel">Generator *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="generatorLabel"
                                                         label="Generator"
                                                         {...register("generator")}
                                                >
                                                    <MenuItem value={"true"}>Yes</MenuItem>
                                                    <MenuItem value={"false"}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.generator?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="technicalRooms"
                                        defaultValue={String(data.RES_ServiciosEspeciales?.SalaTecnica)}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="technicalRoomsLabel">Technical Rooms *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="technicalRoomsLabel"
                                                         label="Technical Rooms"
                                                         {...register("technicalRooms")}
                                                >
                                                    <MenuItem value={"true"}>Yes</MenuItem>
                                                    <MenuItem value={"false"}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.technicalRooms?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="parkingIncluded"
                                        defaultValue={data.RES_ServiciosEspeciales?.Parking}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="parkingIncludedLabel">Parking included
                                                    *</InputLabel>
                                                <Select  {...field} fullWidth
                                                         labelId="parkingIncludedLabel"
                                                         label="Parking included"
                                                         {...register("parkingIncluded")}
                                                >
                                                    <MenuItem value={"yes"}>Yes</MenuItem>
                                                    <MenuItem value={"no"}>No</MenuItem>
                                                    <MenuItem value={'Only Parking'}>Only Parking</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.parkingIncluded?.message}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="Others"
                                        defaultValue={data.RES_ServiciosEspeciales?.Otros}
                                        render={({field}) => (
                                            <FormControl variant="filled" fullWidth>
                                                <InputLabel id="othersLabel">Others</InputLabel>
                                                <Select  {...field} fullWidth
                                                         label="Others">
                                                    <MenuItem value={"Living place"}>Living place</MenuItem>
                                                    <MenuItem value={"Local RLT"}>Local RLT</MenuItem>
                                                    <MenuItem value={'Client site'}>Client site</MenuItem>
                                                    <MenuItem value={'Others'}>Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="completionModuleClosure"
                                        defaultValue={data.RES_ServiciosEspeciales?.FinalizacionCierre}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                {...register("completionModuleClosure")}
                                                fullWidth
                                                variant="filled"
                                                multiline
                                                placeholder='Valid format AAAAMM'
                                                label="Completion/definitive Module closure" />
                                        )}
                                    />
                                    <span style={{color: "red"}}>{errors.completionModuleClosure?.message}</span>
                                </Grid>
                            </Grid>
                            <div className='section section-button mt-20'>
                                <div className='section-button-save'>
                                    <ButtonRequest name="Save Request" disabled={disabled} loading={loading}/>
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
                :<div style={{textAlign: "center"}}>  <CircularProgress /> </div>}
            </ModalBody>
        </Modal>
    </>
}