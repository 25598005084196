import axios from 'axios'
import {REACT_APP_AWS_API_PLATFORM_POST} from '../../../config'

let postPlatform = async(props) => {
    let formulary = {
        nombrePlataforma: props.nombrePlataforma,
        paisPlataforma: props.paisPlataforma,
        domicilioPlataforma: props.domicilioPlataforma,
        ciudadPlataforma: props.ciudadPlataforma,
    };

    return new Promise((resolve, reject) => {   
        axios.post(REACT_APP_AWS_API_PLATFORM_POST, formulary)
        .then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default postPlatform