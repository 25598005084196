import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
    return(
    <tr>
        <td colSpan="6">
            <Box>
                <CircularProgress />
            </Box>
        </td>  
    </tr>
    )
}

export default Loader