import putObject from '../../../Common/put';

// Bussines function for get all measures from aws lambdas
export let updatePeriod = async(method, user, props) => {
    // Initialized object for send
    let alerts = '';   
    //get data from aws lambda
    let response = await putObject(method, user, props);
    //if status is ok, get all data from lambda
    if(response.status === 200) {
        alerts = {
            open: true,
            severity: 'success',
            message: response.data.mensaje
        };
    }else{
        alerts = {
            open: true,
            severity: 'error',
            message: response.data.mensaje
        };
    }

    return alerts;
}