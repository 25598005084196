import axios from 'axios'
import {REACT_APP_AWS_API} from '../../../config'

//Function method for post data from aws api lambda
export let postObject = async(method, user, formulary) => {
    let uri = REACT_APP_AWS_API + method;
    if (user != null && user != undefined && user != "") {
        uri = REACT_APP_AWS_API + method + "/" + user?.profile + "/" + user?.locale;
    }

    return new Promise((resolve, reject) => {   
        axios.post(uri, formulary).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}