import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Box, Grid } from "@mui/material";
import Label from '../../../../Components/Label/Label';
import { getSections } from './Data/ViewSpaceInfo';

export default function ViewSpaces({ data, setOpenedModal, openedModal}) {
    let sections = getSections(data);

    return(
        <Modal isOpen={openedModal} style={{width: "100%", maxWidth: "95%"}}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={() => setOpenedModal(false)}>x</span>
            </ModalHeader>
             <ModalBody>
                <div className="modal-group">
                    <div className='mb-bt15'>
                        <Box>
                            <div>
                                {sections.map((section) => (
                                    <div key={section.title} className="sub-title">
                                        <p style={{marginBottom: "10px"}}>{section.title}</p>
                                        <Grid container spacing={3}>
                                            {section.fields.map((field) => (
                                                <Grid key={field.title} item xs={3}>
                                                    <Label title={field.title} value={field.value} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Box>
                    </div>
                </div>
             </ModalBody>
        </Modal>
    );
}