import axios from 'axios'
import {REACT_APP_AWS_API_MANAGEMENT_DELETE} from '../../../config'

let deleteUser = async(user) => {
    return new Promise((resolve, reject) => {   
        axios.delete(REACT_APP_AWS_API_MANAGEMENT_DELETE + user).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}
export default deleteUser