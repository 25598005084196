import deleteObject from '../../../Common/delete';

/**
 * Construct the property string for deletion based on formulary.
 * 
 * @param {Object} formulary - The formulary containing idperiodo and idespacio.
 * @returns {string} Constructed property string.
 */
const constructDeleteProperty = (formulary) => {
    return `${formulary?.idperiodo}/${formulary?.idespacio}`;
}

/**
 * Handle the response after trying to delete measures.
 * 
 * @param {Object} response - The response from the server.
 * @returns {Object} An alert object based on the response.
 */
const handleDeleteResponse = (response) => {
    const isSuccess = response.status === 200;
    return {
        open: true,
        severity: isSuccess ? 'success' : 'error',
        message: response.data.mensaje
    };
}

/**
 * Business logic responsible for deleting measures.
 * Constructs the property for deletion and then handles the server response.
 * 
 * @param {string} method - The request method.
 * @param {string} user - The user making the request.
 * @param {Object} formulary - The formulary containing data needed for the request.
 * @returns {Promise<Object>} A promise that resolves to an alert object.
 */
export const delMeasures = async (user, method, formulary) => {
    const properties = constructDeleteProperty(formulary);
    const response = await deleteObject(user, method, properties);
    return handleDeleteResponse(response);
}