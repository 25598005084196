import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';

import './ButtonAction.css'

const ButtonTable = (props) => {
  return (
    <div style={{textAlign: "center"}}>
          {props.canEdit
         ? <IconButton aria-label="edit" onClick={props.editRow} style={{color: "rgb(113 132 160)"}}><EditIcon /></IconButton>
          : ''}

        {props.canDelete
         ? <IconButton aria-label="delete" onClick={props.deleteRow} style={{color: "rgb(239 151 151)"}}><DeleteIcon /></IconButton>
          : ''}

        {props.canSeeDetails
         ? <IconButton aria-label="details" onClick={props.detailRow} style={{color: "rgb(212 173 115)"}}><RemoveRedEyeIcon /></IconButton>
          : ''}

        {props.canOpenPeriod
         ? <IconButton aria-label="open" onClick={props.openPeriod} style={{color:  "rgb(230, 176, 119)"}}><LockIcon /></IconButton>
          : ''}         
    </div>
  );
}

export default ButtonTable