import countries from '../../../Controller/Generic/getCountries'

const filtercountries = async(user) => {
    let array = [];
    let response = await countries(user);
    if(response.codigo === 200 ) {
        let data = response?.mensaje.sort((a,b) => a.Nombre > b.Nombre ? 1:-1);
        if(user?.profile == process.env.REACT_APP_ROLE_REG || user?.profile == process.env.REACT_APP_ROLE_PAS) {
            let locale = user?.locale;
            if(locale.includes(',')) {
                let locales = locale.split(',');
                array = data.filter((element) => { return locales.includes(element.Nombre) });
            } else {
                array = data.filter((element) => { return locale == element.Nombre });
            }
        } else {
            array = data;
        }
    }
   
    return array;
}

export default filtercountries;