import axios from 'axios'
import {REACT_APP_AWS_API} from '../../../config'

//Function method for get data from aws api lambda
let getObject = (method, user, props) => {
    //uri for get all data
    let uri = REACT_APP_AWS_API + method;
    
    //check if user is null or not
    if(user != null && user != undefined && user != "") {
        uri = REACT_APP_AWS_API + method +  "/" + user.profile + "/" + user.locale;
    }

    //uri for filter data. Using props for defined ther filter.
    if(props != null && props != undefined && props != "") {
        uri = REACT_APP_AWS_API + method + "/" + props + "/" + user.profile + "/" + user.locale;
    }

    return new Promise((resolve, reject) => { 
        axios.get(uri).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default getObject