import axios from 'axios'
import {REACT_APP_AWS_API_GET_CURRENCIES} from '../../config'

let getCurrencies = (token) => {
    return new Promise((resolve, reject) => { 
        axios.get(REACT_APP_AWS_API_GET_CURRENCIES).then(response=> {
            resolve(response);
        }).catch(error => {
            console.log(error);
            resolve(error.response);
        });
    })
}

export default getCurrencies