import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

import './nav-internal-iframe.css';

const InternalContainer = (props) => (
    <Card sx={{ overflow: 'hidden', backgroundColor: 'white', height: '100%' }}>
        {props.element}
    </Card>
);

export default InternalContainer;