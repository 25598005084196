import React, {useState} from 'react';
import Alert from '../../../../Components/Alerts/message'

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {UpdateSpaceModal} from "./UpdateSpaceModal.js";
import ViewSpaces from "./ViewSpace.js"

export default function UpdateSpace({isOpen, dataRender, user, profile, data, deleteRow}) {
    const [openEdit, setOpenEdit] = useState(isOpen);
    const [openView, setOpenView] = useState(isOpen);
    const [alert, setAlert] = useState({open: false, severity: '', message: ''});

    const closeAlert = () => {
        setAlert({openEdit: false});
    }

    const openEditModal = async () => {
        setOpenEdit(!openEdit);
    }

    const openViewModal = async () => {
        setOpenView(!openView);
    }

    return (
        <div className="containers">
            {profile != "Reading User"
             ? <IconButton aria-label="edit" onClick={() => openEditModal()} style={{color: "rgb(113 132 160)"}}><EditIcon/></IconButton>
             : ""}

            {profile == "Admin" ?
                <IconButton aria-label="delete" onClick={deleteRow} style={{color: "rgb(239 151 151)"}}><DeleteIcon/></IconButton>
             : ''}
            
            {profile == "Reading User"
             ? <IconButton aria-label="details" onClick={()=> {openViewModal()}} style={{color: "rgb(212 173 115)"}}><RemoveRedEyeIcon /></IconButton>
             : ""}

            <Alert open={alert.open} severity={alert.severity} message={alert.message} event={closeAlert}/>

            {openEdit ? <UpdateSpaceModal dataRender={dataRender} data={data} user={user} setOpenedModal={setOpenEdit} openedModal={openEdit} alert={alert} setAlert={setAlert}  /> : null}
            {openView ? <ViewSpaces data={data} setOpenedModal={setOpenView} openedModal={openView} /> : null}
        </div>
    );
}
