import getObject from '../../../Common/get';

/* GET PERIODS */
// Función encargada de obtener datos
const fetchData = async (method, user, props) => {
    return await getObject(method, user, props);
}

// Función para verificar si la respuesta es válida
const isValidResponse = (response) => {
    return response.status === 200;
}

// Función para ordenar datos basados en la fecha
const sortDataByDate = (data) => {
    return data.sort((a, b) => new Date(a.RRHH_Periodo.FechaPeriodo) < new Date(b.RRHH_Periodo.FechaPeriodo) ? 1 : -1);
}

export let getPeriods = async(method, user, props) => {
    const response = await fetchData(method, user, props);
    if (isValidResponse(response)) {
        return sortDataByDate(response.data.respuesta);
    }
    return '';
}

/* GET PERIODS BY ID ZONE */
// Función para obtener datos de AWS Lambda
const fetchDataFromAWS = async (method, user, props) => {
    return await getObject(method, user, props);
}

// Función para ordenar períodos por fecha
const sortByDate = (periods) => {
    return periods.sort((a, b) => new Date(a.RRHH_Periodo.FechaPeriodo) < new Date(b.RRHH_Periodo.FechaPeriodo) ? 1 : -1);
}

export let getPeriodsById = async(method, user, props) => {
    const response = await fetchDataFromAWS(method, user, props);

    if(response.status === 200) {
        return sortByDate(response.data.respuesta);
    }
    return '';
}