export const MenuOptions = [
    {
        path: '/Welcome',
        index: 0,
    },
    {
        path: '/Measures',
        index: 1,
    },
    {
        path: '/Period',
        index: 2,
    },
    {
        path: '/Space',
        index: 3,
    },
    {
        path: '/Request',
        index: 4,
    },
    {
        path: '/Maintenance',
        index: 5,
    },
	{
        path: '/Reports',
        index: 6,
    },
    {
        path: '/Management',
        index: 7,
    },
];