import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Front/App';
import Auth from './auth';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {process.env.REACT_APP_DEVELOP_MODE === 'true'
     ? <App />
     : <Auth />}
  </React.StrictMode>
);

serviceWorkerRegistration.register();
