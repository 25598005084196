const data = [
    {
        name: 'Login'
    },
    {
        name: 'Rol'
    },
    {
        name: 'Country'
    },
    {
        name: 'Action'
    },
]

export default data