import axios from 'axios'
import {
    REACT_APP_AWS_API_MAINTENANCE_PUT_RENTAL_REVIEW,
    REACT_APP_AWS_API_MAINTENANCE_PUT_ENERGETIC_CERTIFICATE,
    REACT_APP_AWS_API_MAINTENANCE_PUT_EXENCTION_CERTIFICATE} from '../../../config'

let putMaintenace = async(props, id, user) => {
    let response = '';
    let url=''

    let formulary={
        Nombre: props.attribute,
        UsuarioModificacion: user?.email,
        FechaModificacion: new Date()
    }

    switch(props.type) {
        case "EGT":
            url = REACT_APP_AWS_API_MAINTENANCE_PUT_ENERGETIC_CERTIFICATE + id;
            response = await CallPut(url, formulary);
            break;
        case "EXT":
            url = REACT_APP_AWS_API_MAINTENANCE_PUT_EXENCTION_CERTIFICATE + id;
            response = await CallPut(url, formulary);
            break;
        case "RT":
            url = REACT_APP_AWS_API_MAINTENANCE_PUT_RENTAL_REVIEW + id;
            response = await CallPut(url, formulary);
            break;
        default:
            response('this code dont exist');
            break;
    }

    return new Promise((resolve) => {     
        resolve(response);
    })
}

let CallPut = async(url, form) => {
    return new Promise((resolve, reject) => {   
        axios.put(url, form).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default putMaintenace;