import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, ProSidebarProvider } from "react-pro-sidebar";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import CircularProgress from '@mui/material/CircularProgress';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import FeedIcon from '@mui/icons-material/Feed';
import {getReports} from '../../../Controller/Generic/getstatusfile'
import {MenuOptions} from './sidebar-menu-options'
import pixelWidth from 'string-pixel-width'
import '../components/menu-options/options.css';
import './sidebar-menu.css';

const _autoFitColumns = (json, worksheet, header) => {
    const jsonKeys = header || Object.keys(json[0])

    const objectMaxLength = []
    jsonKeys.forEach((key) => {
      objectMaxLength.push(
        pixelWidth(key, {
          size: 3,
        })
      )
    })

    json.forEach((data, i) => {
      const value = json[i]
      jsonKeys.forEach((key, j) => {
        const l = value[jsonKeys[j]]
          ? pixelWidth(value[jsonKeys[j]], {
              size: 3,
            })
          : 0
        objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
      })
    })

    return objectMaxLength.map((w) => {
      return { width: w }
    })
  }

  export default function Sidebar(props) { 
    const [fileLoading, setFileLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(() => {
        return MenuOptions.filter(function(value) {return value === window.location.pathname}).index;
    });
    
    const exportReport = async() => {
        setFileLoading(true)
        
        try {
             // get data for print of report
            let dataReport = await getReports(props.user);

            const worksheet = XLSX.utils.json_to_sheet(dataReport?.data?.respuesta);
            const wscols = _autoFitColumns(dataReport?.data?.respuesta, worksheet)
            worksheet['!cols'] = wscols

            const workBook = XLSX.utils.book_new();
        
            // append workbook data to new sheet
            XLSX.utils.book_append_sheet(workBook,worksheet,"datos");
        
            //Generate buffer
            const excelBuffer = XLSX.write(workBook,{bookType:'xlsx',type:"buffer"});
        
            const binarydata = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
            FileSaver.saveAs(binarydata, "SIK RES Informe.xlsx");
            setFileLoading(false)
        } catch (e) {
            setFileLoading(false)
        } 
    }

    return(
        <div className="sidebar">
            <ProSidebarProvider>
                <div className='sidebar-text-main'>
                    <b>Navigation</b>
                </div>
                <Menu>
                <MenuItem active={activeIndex === 0} routerLink={<Link to="/Welcome"></Link>} icon={<HomeIcon />} onClick={() => setActiveIndex(0)}>
                    Home
                </MenuItem>
                </Menu>
                <div className='sidebar-text'>
                    <b>Options</b>
                </div>
                <Menu>
                <MenuItem active={activeIndex === 1} routerLink={<Link to="/Measures"> </Link>} icon={<DataSaverOnIcon />} onClick={() => setActiveIndex(1)}>
                    Measures
                </MenuItem>
                <MenuItem active={activeIndex === 2} routerLink={<Link to="/Period"> </Link>} icon={<CalendarMonthIcon />} onClick={() => setActiveIndex(2)}>
                    Period
                </MenuItem>
                <MenuItem active={activeIndex === 3} routerLink={<Link to="/Space"> </Link>} icon={<WorkspacesIcon />} onClick={() => setActiveIndex(3)}>
                    Space
                </MenuItem>
                {props.user?.profile !== "Reading User" ?
                    <MenuItem active={activeIndex === 4} routerLink={<Link to="/Request"> </Link>} icon={<PostAddIcon />} onClick={() => setActiveIndex(4)}>
                        High Platform Request
                    </MenuItem>
                  : ''}
                {props.user?.profile === "Admin" ?
                                <MenuItem active={activeIndex === 5} routerLink={<Link to="/Maintenance"></Link>} icon={<SettingsIcon />} onClick={() => setActiveIndex(5)}>
                                Maintenance
                            </MenuItem>
                : ''}
                </Menu>
                <Menu>
                    <MenuItem active={activeIndex === 6} icon={fileLoading ? <CircularProgress /> : <FeedIcon />} onClick={() => exportReport()}>
                        Reports
                    </MenuItem>
                </Menu>
                {props.user?.profile === "Admin" ?
                    <><div className='sidebar-text'>
                        <b>Management</b>
                    </div>
                    <Menu>
                        <MenuItem active={activeIndex === 7} routerLink={<Link to="/Management"></Link>} icon={<ManageAccountsIcon />} onClick={() => setActiveIndex(7)}>
                            Management
                        </MenuItem>
                    </Menu></> 
                : ''}

            </ProSidebarProvider>
        </div>
    )
}