import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#162C5E",
  "&:hover": {
    backgroundColor: "#EDEDED"
  },
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

const BlueButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#1A346D"),
  backgroundColor: "#1A346D",
  "&:hover": {
    backgroundColor: "#122349"
  },
  borderRightColor: "white",
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bold",
  textTransform: "none"
}));

export default function ButtonGroupPeriod(props) {
    const buttons = [
        props.user != "Country User" && props.user != "Reading User" ? <WhiteButton key="1" startIcon={<CleaningServicesIcon />} disabled={props.disable} onClick={props.clevent} variant="contained">Clean Filters</WhiteButton> : '',
        props.user == "Admin" ? <WhiteButton key="3" startIcon={<LockOpenIcon />} style={{borderRightColor: "white"}} disabled={props.disable} onClick={props.initial} variant="contained">Open Initial Periods</WhiteButton> : '',
        props.user == "Admin" ? <BlueButton key="3" startIcon={<LockOpenIcon />} style={{borderRightColor: "white"}} disabled={props.disable} onClick={props.stevent} variant="contained">Open Closed Periods</BlueButton> : '',
        props.user != "Reading User" ? <BlueButton key="4" startIcon={<LockIcon />} disabled={props.disable} onClick={props.upevent} variant="contained">Close Periods</BlueButton> : '',
    ];

  return (
    <ButtonGroup size="large" aria-label="large button group">{buttons}</ButtonGroup>
  );
}