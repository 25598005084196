import axios from 'axios'
import {
    REACT_APP_AWS_API_MANAGEMENT_GET_ALL,
    REACT_APP_AWS_API_MANAGEMENT_GET_ROLES} from '../../../config'

let getusers = async(prop) => {
    return new Promise((resolve, reject) => {   
        axios.get(REACT_APP_AWS_API_MANAGEMENT_GET_ALL).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

let getroles = async(prop) => {
    return new Promise((resolve, reject) => {   
        axios.get(REACT_APP_AWS_API_MANAGEMENT_GET_ROLES).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export {
    getusers,
    getroles
}
