export const REACT_APP_AWS_API='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/';
export const REACT_APP_AWS_API_GET_COUNTRIES='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/paisesAllCog';
export const REACT_APP_AWS_API_GET_CURRENCIES='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/monedasCog';
export const REACT_APP_AWS_API_MEASURE_GET_PAISES='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/medidasCog';
export const REACT_APP_AWS_API_MEASURE_PUT='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/medidasCog/';
/*Espacios*/
export const REACT_APP_AWS_API_SPACE_GET_ALL='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosAllCog';
export const REACT_APP_AWS_API_SPACE_GET_BY_ID='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosAllCog/';
export const REACT_APP_AWS_API_SPACE_GET_BY_FILTER='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/desplegablesEspacioValCog/';
export const REACT_APP_AWS_API_SPACE_POST='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosCreateCog/';
export const REACT_APP_AWS_API_SPACE_PUT='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosUpdatedCog/';
export const REACT_APP_AWS_API_SPACE_DELETE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosDeleteCog/';
export const REACT_APP_AWS_API_SPACE_FILTER='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/espaciosFiltradoCog/';
export const REACT_APP_AWS_API_SPACE_STATUS='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/estadoCargaEspacioCog';
/*Mantenimiento*/
export const REACT_APP_AWS_API_PLATFORM_POST='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/createSolicitudPlataformaCog/';
export const REACT_APP_AWS_API_MAINTENANCE_GET_RENTAL_REVIEW='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/indicesCog';
export const REACT_APP_AWS_API_MAINTENANCE_GET_ENERGETIC_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosEnergeticosCog';
export const REACT_APP_AWS_API_MAINTENANCE_GET_EXENCTION_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosExencionCog';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_RENTAL_REVIEW='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_ENERGETIC_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_DELETE_EXENCTION_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosExencionCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_RENTAL_REVIEW='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_ENERGETIC_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_POST_EXENCTION_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosExencionCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_RENTAL_REVIEW='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/indicesCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_ENERGETIC_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosEnergeticosCog/';
export const REACT_APP_AWS_API_MAINTENANCE_PUT_EXENCTION_CERTIFICATE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/certificadosExencionCog/';
export const REACT_APP_AWS_API_MANAGEMENT_GET_ALL='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/usuariosCog';
export const REACT_APP_AWS_API_MANAGEMENT_GET_ROLES='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/rolesCog';
export const REACT_APP_AWS_API_MANAGEMENT_POST='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/usuariosCog';
export const REACT_APP_AWS_API_MANAGEMENT_PUT='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/usuariosCog/';
export const REACT_APP_AWS_API_MANAGEMENT_DELETE='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/usuariosCog/';
export const REACT_APP_AWS_API_MANAGEMENT_POST_PASSWORD='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/setPassword/';
export const REACT_APP_AWS_API_SPACE_UPLOAD='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/cargaMasivaEspacioCog';
export const REACT_APP_AWS_API_MEASURES_UPLOAD='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/cargaMasivaMedidaCog';
export const REACT_APP_AWS_API_MEASURE_STATUS='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/estadoCargaMedidaCog';
export const REACT_APP_AWS_API_REPORTS='https://24az0zeeqb.execute-api.eu-west-1.amazonaws.com/dev/informesCog';