import React, { useState, useEffect } from 'react';
import Platform from './Pages/Platform'
import './Container.css';

function Container(props) {
  const [user, setUser] = useState(props?.user);

  useEffect(() => {
    setUser(props?.user)
  });

  return (
    <div className="App">
        <div>
            <div className='request-header'>
                <p>High Platform Request</p>
            </div>
            <div className='request-form'>
              {user ? <Platform user={user}/> : ''}
            </div>
        </div>
    </div>
  );
}

export default Container;