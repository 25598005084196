import React, { useState } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ButtonCancel from '../../../../Components/Buttons/ButtonCancel'
import ButtonSend from '../../../../Components/Buttons/ButtonSend'
import Alert from '../../../../Components/Alerts/message'
import ButtonsGroup from '../../../../Components/Buttons/ButtonGroupFiles'

import {getMeasuresStatusFile} from '../../../../../Controller/Generic/getstatusfile'
import {uploadmeasuremasive} from '../../../../../Controller/Generic/uploadfile'

export default function UploadFile({cleanComponents, user}) {
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [disabledUpload, setDisabledUpload] = useState(false);
    const [isupload, setIsUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [base64URL, setBase64URL] = useState('');
    const [file, setFile] = useState('');
    const [alert, setAlert] = useState({ open: false, severity: '', message: ''});
    const [status, setStatus] = useState(false);
    const [dataFileStatus, setDataFileStatus] = useState('');

    const changeHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(e.target.files[0]);
    
        let file = e.target.files[0];
    
        getBase64(file)
          .then(result => {
            file["base64"] = result;
            console.log("File Is", file);
            setBase64URL(result)
            setFile(e.target.files[0])
          })
          .catch(err => {
            console.log(err);
          }); 
      };

      const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
      
            // Convert the file to base64 text
            reader.readAsDataURL(file);
      
            // on reader load somthing...
            reader.onload = () => {
              // Make a fileInfo Object
              console.log("Called", reader);
              baseURL = reader.result;
              console.log(baseURL);
              resolve(baseURL);
            };
            console.log(fileInfo);
          });
      }    

      const handleSubmission = async() => {
        setDisabledUpload(true);
        setLoadingUpload(true);

        const formData = new FormData();

        formData.append('File', selectedFile);

        let formulary = {
            plantilla: "medida",
            nombreFichero: file.name,
            ficheroCarga: base64URL.split(',')[1]
        }

        let response = await uploadmeasuremasive(formulary);
        if(response.status === 200) {
            setAlert({ open: true, severity: 'success', message: 'File upload successfully.'});
        } else {
            setAlert({ open: true, severity: 'error', message: 'Error while upload file.'});
        }

        setDisabledUpload(false);
        setLoadingUpload(false);
        
        openupload();
    };

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet([]);
        /* custom headers */
        XLSX.utils.sheet_add_aoa(ws, [["Period", "Module Code", "Country","Constructed surface area","No. PA,s Teleoperator","No. PA,s Coord/Superv.","No. PA,s Structure","No. Seat's Meeting Room","No. Seat's Training","No. Seat,s Office/dining room/break rooms","No. Seat,s Other uses","No. Parking places","Monthly Rent Amount","Community Cost/Equivalent","Repair and maintenance","Cleaning","Security","Medical Center","Electricity","Water","Communications","Office supplies","Transports and transfers","Office technical staff","Network Electronics","Others", "RET (Real Estate Tax)", "Activity Taxes (Garabage tax)"]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, "Series Template.xlsx");
    };

    const closeAlert=()=>{
        setAlert({ open: false});
      }

    const openupload=()=>{
        setIsUpload(!isupload);
    }

    const openstatus=async()=>{
      let datafile = await getMeasuresStatusFile(user);
      setDataFileStatus(datafile.data.respuesta);
      setStatus(!status);
    }

      return ( 
        <div>
            <ButtonsGroup disable={false}
                clevent={()=>{ cleanComponents() }}
                stevent={()=>{ openstatus() }}
                ddevent={()=>{ exportToCSV() }}
                upevent={()=>{ openupload() }}
                user={user}
            />

            <Alert open={alert.open} severity={alert.severity} message={alert.message} event={closeAlert}/>

            {/* Modal upload */}
            <Modal isOpen={isupload}>
                <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                    <span style={{float: 'right'}} onClick={()=> openupload()}>x</span>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <label for="file" className="drop-container">
                            <span className="drop-title">Drop files here</span>
                            or
                            <input type="file" id="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required onChange={changeHandler}/>
                        </label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonSend event={() => handleSubmission()} disabled={disabledUpload} loading={loadingUpload} name="Upload" />
                    <ButtonCancel event={() => openupload()} name="Cancel" />
                </ModalFooter>
            </Modal>

            <Modal isOpen={status} style={{width: "100%", maxWidth: "50%"}}>
            <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                <span style={{float: 'right'}} onClick={()=> openstatus()}>x</span>
            </ModalHeader>
            <ModalBody>
                <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th key={1}> File Name</th>
                            <th key={2}> Total Rows</th>
                            <th key={3}> Row Charged</th>
                            <th key={4}> Status</th>
                            <th key={5}> Date</th>
                            <th key={6}> Message</th>
                        </tr>
                    </thead>
                    <tbody>	
                        <tr key={1}>
                            <td>{dataFileStatus?.NombreFichero}</td>
                            <td>{dataFileStatus?.FilasTotales}</td>
                            <td>{dataFileStatus?.FilasCargadas}</td>
                            <td>{dataFileStatus?.Estado}</td>
                            <td>{dataFileStatus?.Fecha}</td>
                            <td>{dataFileStatus?.Mensaje}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonCancel event={() => openstatus()} name="Cancel" />
            </ModalFooter>
        </Modal>
        </div>
      );
}