export const getSections = (data) => [
    {
        title: "Basic Data",
        fields: [
            { title: "Module Name", value: data.Nombre },
            { title: "Module Country", value: data.Pais.Nombre },
            { title: "Associate Work Center", value: data.Plataforma.Nombre },
            { title: "Module Address/Description", value: data.Direccion },
            { title: "Module Town/District", value: data.Ubicacion },
            { title: "Leasing Company", value: data.RRHH_EmpresaSIK.Nombre },
        ]
    },
    {
        title: "Rent and Associate Expenses",
        fields: [
            { title: "Contract Currency", value: data.RES_RentaGastos?.CodigoMonedaFacturacion},
            { title: "Rental Review (Index)", value: data.RES_RentaGastos?.RES_Indice?.Nombre},
            { title: "Spread over the index", value: data.RES_RentaGastos?.DistribuidoSobreIndice  },
            { title: "% Revision index if the index selector is fixed rate", value: data.RES_RentaGastos?.RevisionIndice },
            { title: "Month of rent review", value: data.RES_RentaGastos?.RevisionMesAlquiler },
            { title: "First contract starting date", value: data.RES_RentaGastos?.FechaPrimerContrato },
        ]
    },
    {
        title: "Last Contract",
        fields: [
            { title: "Start/Renewal Date (Last)", value: data.RES_Contrato?.FechaComienzo },
            { title: "Term (months)", value: data.RES_Contrato?.Duracion },
            { title: "Lack (months)", value: data.RES_Contrato?.TiempoRestante },
            { title: "Possible extension (months)", value: data.RES_Contrato?.PosibleExtension },
            { title: "Mandatory Compliance Time (months)", value: data.RES_Contrato?.CumplimientoObligatorio },
            { title: "End of Mandatory Compliance date", value: data.RES_Contrato?.FinCumplimientoObligatorio },
            { title: "Due Date Term", value: data.RES_Contrato?.FechaVencimiento },
            { title: "Due Date Extension", value: data.RES_Contrato?.FechaExtension },
            { title: "Early Departure (Y/N)", value: data.RES_Contrato?.SalidaAnticipada ? "Yes" : "No" },
            { title: "Notice (months)", value: data.RES_Contrato?.Aviso },
            { title: "Extension", value: data.RES_Contrato?.Extension },
            { title: "IFRS", value: data.RES_Contrato?.IFRS },
        ]
    },
    {
        title: "Activity License",
        fields: [
            { title: "Type of Procedure", value: data.RES_LicenciaActividad?.Procedimiento },
            { title: "Registration/Grant", value: data.RES_LicenciaActividad?.RegistroBeca },
            { title: "License Holder", value: data.RES_LicenciaActividad?.IdRRHH_EmpresaSIK},
            { title: "Energetic Certification", value: data.RES_LicenciaActividad?.RES_CertificadoEnergetico.Nombre },
            { title: "Withholding Exemption Certificate", value: data.RES_LicenciaActividad?.RES_CertificadoExencionRetencion.Nombre},
            { title: "Bail/Deposit", value: data.RES_LicenciaActividad?.Deposito},
            { title: "Warranty", value: data.RES_LicenciaActividad?.Garantia},
            { title: "Endorsement Amount", value: data.RES_LicenciaActividad?.ImporteGarantia},
            { title: "Endorsement Renewal Date", value: data.RES_LicenciaActividad?.FechaRenovacionGarantia },
            { title: "Endorsement Expiration Date", value: data.RES_LicenciaActividad?.FechaVencimientoGarantia },
            { title: "Landlord", value: data.RES_LicenciaActividad?.Dueno },
            { title: "Landlord Address", value: data.RES_LicenciaActividad?.DireccionDueno },
        ]
    },
    {
        title: "Contact",
        fields: [
            { title: "Phone/Contact 1", value: data.RES_LicenciaActividad?.Movil1 },
            { title: "Phone/Contact 2", value: data.RES_LicenciaActividad?.Movil2 },
            { title: "Phone/Contact 3", value: data.RES_LicenciaActividad?.Movil3 },
            { title: "Email/Contact 1", value: data.RES_LicenciaActividad?.Email1 },
            { title: "Email/Contact 2", value: data.RES_LicenciaActividad?.Email2 },
            { title: "Email/Contact 3", value: data.RES_LicenciaActividad?.Email3 },
            { title: "Fax", value: data.RES_LicenciaActividad?.Fax },
            { title: "Other Channels Of Contact", value: data.RES_LicenciaActividad?.OtroContacto },
            { title: "Observations", value: data.RES_LicenciaActividad?.Observaciones },
        ]
    },
    {
        title: "Special Endowment",
        fields: [
            { title: "Electrical Transformation Center", value: String(data.RES_ServiciosEspeciales?.CentroTransformacionElectrica) },
            { title: "Generator", value: String(data.RES_ServiciosEspeciales?.Generador) },
            { title: "Technical Rooms", value: String(data.RES_ServiciosEspeciales?.SalaTecnica) },
            { title: "Parking included", value: data.RES_ServiciosEspeciales?.Parking },
            { title: "Others", value: data.RES_ServiciosEspeciales?.Otros },
            { title: "Completion/definitive Module closure", value: data.RES_ServiciosEspeciales?.FinalizacionCierre },
        ]
    },
];