import "./Pagination.css";
import classNames from "classnames";

const range = (start, end) => {
  return [...Array(end - start).keys()].map((el) => el + start);
};

const getPagesCut = ({ pagesCount, pagesCutCount, currentPage }) => {
  const ceiling = Math.ceil(pagesCutCount / 2);
  const floor = Math.floor(pagesCutCount / 2);
  console.log("ceiling", ceiling);
  console.log("floor", floor);

  if (pagesCount < pagesCutCount) {
    return { start: 1, end: pagesCount + 1 };
  } else if (currentPage >= 1 && currentPage <= ceiling) {
    return { start: 1, end: pagesCutCount + 1 };
  } else if (currentPage + floor >= pagesCount) {
    return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
  } else {
    return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
  }
};

const PaginationItem = ({ page, currentPage, onPageChange, isDisabled }) => {
  const liClasses = classNames({
    "page-item": true,
    active: page === currentPage,
    disabled: isDisabled,
  });
  return (
    <li className={liClasses} onClick={() => onPageChange(page)}>
      <span className="page-link">{page}</span>
    </li>
  );
};

const Pagination = ({ currentPage, pagesCount, setCurrentPage }) => {
  const pagesCut = getPagesCut({ pagesCount, pagesCutCount: 5, currentPage });
  const pages = range(pagesCut.start, pagesCut.end);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pagesCount;
  return (
    <div className="pagination-table">
        <ul className="pagination">
            <PaginationItem
                page="First"
                currentPage={currentPage}
                onPageChange={() => setCurrentPage(1)}
                isDisabled={isFirstPage}
            />
            {pagesCount > 1 && !isFirstPage ? 
            <PaginationItem
                page="Prev"
                currentPage={currentPage}
                onPageChange={() => setCurrentPage(currentPage - 1)}
                isDisabled={isFirstPage}
            />
            : ''}
            {pages.map((page) => (
                <PaginationItem
                page={page}
                key={page}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                />
            ))}
            {pagesCount > 1 && !isLastPage? 
            <PaginationItem
                page="Next"
                currentPage={currentPage}
                onPageChange={() => setCurrentPage(currentPage + 1)}
                isDisabled={isLastPage}
            />
            : ''}
            <PaginationItem
                page="Last"
                currentPage={currentPage}
                onPageChange={() => setCurrentPage(pagesCount)}
                isDisabled={isLastPage}
            />
            </ul>
    </div>
    
  );
};
export default Pagination;
