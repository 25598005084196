import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './Welcome.css';

let Welcome = () => {
    return(
        <Container fluid>
            <Row>
                <div className='text-containers'>
                    <p><b>Welcome!</b></p>
                    <p>This is the Real Estate Application.</p>
                </div>
            </Row>
        </Container>
    )
}

export default Welcome;