import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_POST} from '../../../config'

let postSpace = async(props, user) => {
    return new Promise((resolve, reject) => {   
        let uri = REACT_APP_AWS_API_SPACE_POST + user?.profile + "/" + user?.locale;
        axios.post(uri, JSON.stringify(props)).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default postSpace