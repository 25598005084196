import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_STATUS,
    REACT_APP_AWS_API_MEASURE_STATUS,
    REACT_APP_AWS_API_REPORTS} from '../../config'

let getSpaceStatusFile = (user) => {
    return new Promise((resolve, reject) => { 
        let uri = REACT_APP_AWS_API_SPACE_STATUS;
        axios.get(uri).then(response=> {
            resolve(response);
        }).catch(error => {
            console.log(error);
            resolve(error.response);
        });
    })
}

let getMeasuresStatusFile = (user) => {
    return new Promise((resolve, reject) => { 
        let uri = REACT_APP_AWS_API_MEASURE_STATUS;
        axios.get(uri).then(response=> {
            resolve(response);
        }).catch(error => {
            console.log(error);
            resolve(error.response);
        });
    })
}

let getReports = (user) => {
    return new Promise((resolve, reject) => { 
        let uri = REACT_APP_AWS_API_REPORTS + "/" + user?.profile + "/" + user?.locale;
        axios.get(uri).then(response=> {
            resolve(response);
        }).catch(error => {
            console.log(error);
            resolve(error.response);
        });
    })
}

export {
    getSpaceStatusFile,
    getMeasuresStatusFile,
    getReports
}