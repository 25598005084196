import deleteObject from '../../../Common/delete';

//function method for delete measures
export let delPeriods = async(method, data) => {
    // Initialized object for send
    let alerts = '';
    //function method for delete measures.
    let response = await deleteObject(method, data.id);
    if(response.status === 200) {
        alerts = {
            open: true,
            severity: 'success',
            message: response.data.mensaje
        };
    }else{
        alerts = {
            open: true,
            severity: 'error',
            message: response.data.mensaje
        };
    }

    return alerts;
}