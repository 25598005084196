import {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './Dashboard/navbar-menu/navbar-menu';
import Sidebar from './Dashboard/sidebar-menu/sidebar-menu';
import InternalContainer from './Dashboard/Iframe/internal/nav-internal-iframe'

import Welcome from './Pages/Welcome/Welcome'
import Measures from './Pages/Measures/Container'
import Period from './Pages/Period/Container'
import Platform from './Pages/Platform/Container'
import Space from './Pages/Space/Container'
import UserManagement from './Pages/User/Container'
import Maintenance from './Pages/Maintenance/Container'

import './App.css';

const sendEvent = (worker, data) =>
    new Promise((res, rej) => {
        const channel = new MessageChannel();

        channel.port1.onmessage = ({ data }) => {
            channel.port1.close();

            if (data.error) {
                rej(data.error);
            } else {
                res(data.result);
            }
        };

        worker.active.postMessage([data], [channel.port2]);
    });

function App(props) {
  const [user, setUser] = useState('');
  const [credential, setCredential] = useState('');
  const [worker, setWorker] = useState(null);

    useEffect(() => {
        navigator.serviceWorker.ready.then((worker) => {
            setWorker(worker);
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (worker)
                sendEvent(worker, {
                    type: "HAVE_VALID_CREDENTIALS",
                }).then((result) => {
                    if (result) {         
                        return;
                    }
                });
        }, 1000);

        return () => clearInterval(interval);
    }, [worker]);

    useEffect(() => {
      (async () => {
          setCredential(props.credentials);
          setUser(props.user);
      })();
    }, []);

  return (
    <Router>
        <Navbar user={user} />
        <div className='flex'>
          <Sidebar user={user} />
          <div className='content'>
            <Routes>
              <Route index path="/Welcome" exact={true} element={ <InternalContainer element={<Welcome />}/> } />
              <Route path="/Measures" exact={true} element={ <InternalContainer element={<Measures user={user} credential={credential}/>}/> } />
              <Route path="/Period" exact={true} element={ <InternalContainer element={<Period user={user} credential={credential}/>}/> } />
              <Route path="/Space" exact={true} element={ <InternalContainer element={<Space user={user} credential={credential}/>}/>  } />
              <Route path="/Request" exact={true} element={ <InternalContainer element={<Platform user={user} credential={credential}/>}/> } />
              {props.user?.profile === "Admin" ?
                <Route path="/Maintenance" exact={true} element={ <InternalContainer element={<Maintenance user={user} credential={credential}/>}/> } />
                : ''}
              {props.user?.profile === "Admin" ?
                  <Route path="/Management" exact={true} element={ <InternalContainer element={<UserManagement user={user} credential={credential}/>}/> }  />
               : ""} 
            </Routes>
          </div>
        </div>
    </Router>
  );
}

export default App;