import React, { useState, useEffect } from 'react';
import Measures from './Pages/Measures'
import './Container.css';

function Container(props) {
  const [user, setUser] = useState(props?.user);

  useEffect(() => {
    setUser(props?.user)
  });

  return (
    <div className="App">
        <div>
            <div className='request-form'>
              {user ? <Measures user={user} credential={props.credential}/> : ''}
            </div>
        </div>
    </div>
  );
}

export default Container;