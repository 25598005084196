import React, {Component} from 'react';
import logo from './logo.png';
import './logo.css';

class Logo extends Component {
    render() {
        return(
            <div className="navbar-containers-icon">
                <img src={logo} />
            </div>
        ) 
    }
}

export default Logo;