import axios from 'axios'
import {REACT_APP_AWS_API_MANAGEMENT_POST, REACT_APP_AWS_API_MANAGEMENT_POST_PASSWORD} from '../../../config'

let postUser = async(props) => { 
    let formulary={
        Username: props.Email,
        UserAttributes:[{
            Name: "profile",
            Value: props.Rol
        },
        {
            Name: "locale",
            Value: props.Pais
        },
        {
            Name: 'email_verified',
            Value: 'True'
        },
        {
            Name: 'email',
            Value: props.Email,
        }]
    }

    return new Promise((resolve, reject) => {   
        axios.post(REACT_APP_AWS_API_MANAGEMENT_POST, formulary).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

let setPassword = async(form) => { 
    return new Promise((resolve, reject) => {   
        axios.post(REACT_APP_AWS_API_MANAGEMENT_POST_PASSWORD, form).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export {
    postUser,
    setPassword
}