import {postObject} from '../../../Common/post';

export let postMaintenance = async(props, user) => {
	// Initialized object for send
    let alerts =  {
        open: true,
        severity: 'error',
        message: "Error when deleting. No record has been selected to delete. Contact the website administrator for more details."
    };

    let formulary={
        Nombre: props.attribute,
        UsuarioInsercion: user?.email,
        FechaInsercion: new Date()
    }

    switch(props.type) {
        case "EGT":
            //function method for delete energetic certification measures.
            let energetic = await postObject("certificadosEnergeticosCog", null, formulary);
            alerts = setAlerts(energetic);
            break;
        case "EXT":
            //function method for delete energetic certification measures.
            let exeption = await postObject("certificadosExencionCog", null, formulary);
            alerts = setAlerts(exeption);
            break;
        case "RT":
            let index = await postObject("indicesCog", null, formulary);
            alerts = setAlerts(index);
            break;
    }
    
    return alerts;
}

//function method for set alerts adn show on front
let setAlerts = (values) => {
	let response = '';
	//if response is OK, show alert success.
	if (values.data.codigo >= 200 && values.data.codigo <= 202) {
        response = {
            open: true,
            severity: 'success',
            message: values.data.mensaje
        };
    } else {
		//if response is NOK, show alert error.
        response = {
            open: true,
            severity: 'error',
            message: values.data.mensaje
        };
    }
	
	return response
}