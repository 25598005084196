import Sidebar from './Dashboard/sidebar-menu/internal-sidebar-menu';
import Maintenance from './Pages/Maintenance'
import './Container.css';

function Container(props) {
  return (
    <div className="wd-100">
        <div className='request-header'>
          <p>Maintenance</p>
        </div>
        <div className='flex'>
          <Sidebar />
          <div className='internal-content'>
            <Maintenance user={props.user}/>
          </div>
        </div>
    </div>
  );
}

export default Container;
