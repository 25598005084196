import axios from 'axios'
import {REACT_APP_AWS_API_SPACE_GET_ALL, REACT_APP_AWS_API_SPACE_GET_BY_ID, REACT_APP_AWS_API_SPACE_GET_BY_FILTER, REACT_APP_AWS_API_SPACE_FILTER} from '../../../config'

export let getSpaces = async (props) => {
    try {
        const response = await axios.get(REACT_APP_AWS_API_SPACE_GET_ALL + "/" + props?.profile + "/" + props?.locale);

        switch (props?.profile) {
            case "Regional User":
                const countries = props.locale.includes(',')
                    ? props.locale.split(',')
                    : [props.locale];
                const databyrolecountries = response.data.respuesta.filter(element => countries.includes(element.Pais.Nombre));
                return {
                    status: response.status,
                    data: { respuesta: databyrolecountries }
                };

            case "Country User":
            case "Reading User":
                const databyrolecountry = response.data.respuesta.filter(element => props.locale === element.Pais.Nombre);        
                return {
                    status: response.status,
                    data: { respuesta: databyrolecountry }
                };

            case "Admin":
            case "Corporate User":
                return response;

            default:
                return undefined;
        }
    } catch (error) {
        return error.response;
    }
};

export let getSpacesById = async(id, user) => {
    return new Promise((resolve, reject) => {   
    
        axios.get(REACT_APP_AWS_API_SPACE_GET_BY_ID + id + "/" + user?.profile + "/" + user?.locale).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export let getFiltersByCountryId = async(id, user) => {
    return new Promise((resolve, reject) => {   
    
        axios.get(REACT_APP_AWS_API_SPACE_GET_BY_FILTER + id +"/" + user?.profile + "/" + user?.locale).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export let getSpaceByFilter = async (prop, user) => {
    if (!prop) return;

    const locations = prop.locations || null;
    const name = prop.modulename || null;
    const code = prop.code || null;
    const country = prop.country || null;

    const uri = `${REACT_APP_AWS_API_SPACE_FILTER}${locations}/${name}/${code}/${country}/${user?.profile}/${user?.locale}`;

    try {
        const response = await axios.get(uri);

        switch (user?.profile) {
            case "Regional User":
                const countries = user.locale.includes(',')
                ? user.locale.split(',')
                : [user.locale];
                const databyrolecountries = response.data.respuesta.filter(element => countries.includes(element.Pais.Nombre));

                return {
                    status: response.status,
                    data: { respuesta: databyrolecountries }
                };

            case "Country User":
            case "Reading User":
                const databyrolecountry = response.data.respuesta.filter(element => user?.locale === element.Pais.Nombre);

                return {
                    status: response.status,
                    data: { respuesta: databyrolecountry }
                };

            case "Admin":
            case "Corporate User":
                return response;

            default:
                return undefined;
        }
    } catch (error) {
        return error.response;
    }
};
