import React, { useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import { getPeriods } from '../../Business/Events/periodget';
import ButtonSend from '../../../../Components/Buttons/ButtonSend'
import ButtonCancel from '../../../../Components/Buttons/ButtonCancel'
import ButtonsGroup from '../../../../Components/Buttons/ButtonGroupPeriod'
import Alert from '../../../../Components/Alerts/message'

/* Data */
import { updatePeriod } from '../../Business/Events/periodput';

export default function CreatePeriod({isOpen, data, dataRender, id, cleanComponents, user}) {
    const [openModifyModalPeriod, setOpenModifyModalPeriod] = useState(false);
    const [openInitialModalPeriod, setOpenInitialModalPeriod] = useState(false);
    const [isOpenPeriod, setIsOpenPeriod] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dateModify, setDateModify] = useState(new Date());
    const [alert, setAlert] = useState({ open: false, severity: '', message: ''});

    const getData=async() =>{
        return await getPeriods("periodosPaisAllCog", user, null);
    }

    const handleDateModify = (data) => {
        setDateModify(new Date(data.$y, data.$M));
    }

    const openModifyPeriod=()=>{
        setOpenModifyModalPeriod(!openModifyModalPeriod);
    }

    const openInitialPeriod=()=>{
        setOpenInitialModalPeriod(!openInitialModalPeriod);
    }

    const closeAlert=()=>{
        setAlert({ open: false});
    }

    const openAllInitPeriod=async()=> {
        let idperiodos = [];
        let dateperiod =await getData();
        let date = (dateModify.getFullYear() + ((dateModify.getMonth() + 1) < 10 ? "-0" + (dateModify.getMonth() + 1) : "-" + (dateModify.getMonth() + 1)) + "-01" );
        let periods = dateperiod.filter((element) => {return element.RES_EstadoPeriodo.Alias === 'Initial' && element.RRHH_Periodo.FechaPeriodo == date});
        periods.forEach((element) => idperiodos.push({Id: parseInt(element.Id)}));
        
        if(idperiodos.length > 0) {
            let properties = {
                formulary: {
                    Periodos: idperiodos,
                    Estado: 'Open',
                    UsuarioModificacion: user?.email
                }      
            }

            let response = await updatePeriod("periodosPaisUpdatedCog", user, properties);
            setAlert({
                open: response.open,
                severity: response.severity,
                message: response.message
            });
            dataRender(id);
        } else {
            setAlert({ open: true, severity: 'success', message: "Don't have any period with initial status."});
        }
       
        setDisabled(false);
        setLoading(false);
        openInitialPeriod();
    }

    const openAllClosedPeriod=async()=> {
        let idperiodos = [];
        let dateperiod =await getData();
        let date = (dateModify.getFullYear() + ((dateModify.getMonth() + 1) < 10 ? "-0" + (dateModify.getMonth() + 1) : "-" + (dateModify.getMonth() + 1)) + "-01" );
        let periods = dateperiod.filter((element) => {return element.RES_EstadoPeriodo.Alias === 'Closed' && element.RRHH_Periodo.FechaPeriodo == date});
        periods.forEach((element) => idperiodos.push({Id: parseInt(element.Id)}));

        let properties = {
            formulary: {
                Periodos: idperiodos,
                Estado: 'Open',
                UsuarioModificacion: user?.email
            }      
        }

        let response = await updatePeriod("periodosPaisUpdatedCog", user, properties);
        setAlert({
            open: response.open,
            severity: response.severity,
            message: response.message
        });

        dataRender(id);
        setDisabled(false);
        setLoading(false);
        openModifyPeriod();
    }

    const closeAllPeriods=async()=> {
        let idperiodos = [];
        let dateperiod =await getData();
        let date = (dateModify.getFullYear() + ((dateModify.getMonth() + 1) < 10 ? "-0" + (dateModify.getMonth() + 1) : "-" + (dateModify.getMonth() + 1)) + "-01" );
        let periods = dateperiod.filter((element) => {return element.RES_EstadoPeriodo.Alias === 'Open' && element.RRHH_Periodo.FechaPeriodo == date});
        periods.forEach((element) => idperiodos.push({Id: element.Id}));

        let properties = {
            formulary: {
                Periodos: idperiodos,
                Estado: 'Closed',
                UsuarioModificacion: user?.email
            }      
        }

        let response = await updatePeriod("periodosPaisUpdatedCog", user, properties);
        setAlert({
            open: response.open,
            severity: response.severity,
            message: response.message
        });

        dataRender(id);
        setDisabled(false);
        setLoading(false);
        openModifyPeriod();
    }

    return (
        <div className=''>
            <ButtonsGroup disable={false}
                clevent={()=>{ cleanComponents() }}
                initial={() => {openInitialPeriod()}}
                stevent={() => {setIsOpenPeriod(true); openModifyPeriod()}}
                upevent={() => {setIsOpenPeriod(false); openModifyPeriod()}}
                user={user?.profile}
            />
            <Alert open={alert.open} severity={alert.severity} message={alert.message} event={closeAlert}/>
            <Modal isOpen={openModifyModalPeriod}>
                <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                    <span style={{float: 'right'}} onClick={()=>openModifyPeriod()}>x</span>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <div className='mb-bt15'>
                            <div>
                                <Box sx={{ maxWidth: '100%' }}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['month', 'year']}
                                                label="Date Insert"
                                                id="FechaInsercion"
                                                name="FechaInsercion"
                                                value={dateModify === null || dateModify === '' ? '' : dateModify}
                                                onChange={handleDateModify}             
                                                renderInput={(params) => <TextField variant="filled" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='modal-footer-custom'>
                    <ButtonSend event={() => {setDisabled(true); setLoading(true); isOpenPeriod ? openAllClosedPeriod() : closeAllPeriods() }} disabled={disabled} loading={loading} name="Send"/>   
                    <ButtonCancel event={() => openModifyPeriod()} name="Cancel"/>
                </ModalFooter>
            </Modal>
            <Modal isOpen={openInitialModalPeriod}>
                <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                    <span style={{float: 'right'}} onClick={()=>openInitialPeriod()}>x</span>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <div className='mb-bt15'>
                            <div>
                                <Box sx={{ maxWidth: '100%' }}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['month', 'year']}
                                                label="Date Insert"
                                                id="FechaInsercion"
                                                name="FechaInsercion"
                                                value={dateModify === null || dateModify === '' ? '' : dateModify}
                                                onChange={handleDateModify}             
                                                renderInput={(params) => <TextField variant="filled" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='modal-footer-custom'>
                    <ButtonSend event={() => {setDisabled(true); setLoading(true); openAllInitPeriod()}} disabled={disabled} loading={loading} name="Send"/>   
                    <ButtonCancel event={() => openInitialPeriod()} name="Cancel"/>
                </ModalFooter>
            </Modal>
        </div> 
  );
}