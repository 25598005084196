import React from 'react';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Message=(props)=>{
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = state;

  return (
    <div>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={props.open} autoHideDuration={4000} onClose={props.event} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
            <Alert onClose={props.event} severity={props.severity} sx={{ width: '100%' }}>
              {props.message}
            </Alert>
          </Snackbar>
        </Stack>
    </div> 
  );
}

export default Message