const data = [
    {
        name: 'Year',
        style: 'th-month'
    },
    {
        name: 'Month',
        style: 'th-month'
    },
    {
        name: 'Country',
        style: ''
    },
    {
        name: 'Status',
        style: ''
    },
    {
        name: 'Action',
        style: 'th-action'
    },
]

export default data