import deleteObject from '../../../Common/delete';

export let deleteMaintenanceByType = async(props, user) => {
	// Initialized object for send
    let alerts =  {
        open: true,
        severity: 'error',
        message: "Error when deleting. No record has been selected to delete. Contact the website administrator for more details."
    };
    
    if(props != null && (props.id != null && props.id != undefined && props.id != "")) {
        switch(props.code) {
            case "EGT":
                //function method for delete energetic certification measures.
                let energetic = await deleteObject(null, "certificadosEnergeticosCog/", props.id);
                alerts = setAlerts(energetic);
                break;
            case "EXT":
                //function method for delete energetic certification measures.
                let exeption = await deleteObject(null, "certificadosExencionCog/", props.id);
                alerts = setAlerts(exeption);
                break;
            case "RT":
                let index = await deleteObject(null, "indicesCog/", props.id);
                alerts = setAlerts(index);
                break;
        }
    }
    
    return alerts;
}

//function method for set alerts adn show on front
let setAlerts = (values) => {
	let response = '';
	//if response is OK, show alert success.
	if (values.status === 200) {
        response = {
            open: true,
            severity: 'success',
            message: values.data.mensaje
        };
    } else {
		//if response is NOK, show alert error.
        response = {
            open: true,
            severity: 'error',
            message: values.data.mensaje
        };
    }
	
	return response
}