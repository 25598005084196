import React, { useEffect, useState } from "react";
import { useAuthStore } from "../state/Auth";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Box, Grid } from "@mui/material";
import { deepOrange} from '@mui/material/colors';
import Logout from './logout';
import './../menu-logo/logo';

const settings = ['Logout'];
const sendEvent = (worker, data) =>
  new Promise((res, rej) => {
    const channel = new MessageChannel();

    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();

      if (data.error) {
        rej(data.error);
      } else {
        res(data.result);
      }
    };

    worker.active.postMessage([data], [channel.port2]);
  });

export default function User(props) { 
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const setAuthenticated = useAuthStore((state) => state.setAuthenticated);
  
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        logout();
    };

    const logout = () => {
        navigator.serviceWorker.ready.then((worker) => {
            if (worker){
                sendEvent(worker, {
                    type: "GET_SIGN_OUT",
                }).then((result) => {
                    setAuthenticated(false);
                    if (worker){
                        sendEvent(worker, {
                            type: "GET_SIGN_OUT",
                        }).then((result) => {
                            setAuthenticated(false);
                            window.location.reload();
                        });
                    }
                });
            }
        });

    }

      
        return(
            <div className="navbar-containers-icon user">
                <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ bgcolor: deepOrange[500] }}>SIK</Avatar>
                                </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    >
                                    <MenuItem key="Logout" onClick={() => handleCloseUserMenu()}>
                                            <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                    </Grid>
            </div>
        )
    }