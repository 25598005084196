import axios from 'axios'
import {REACT_APP_AWS_API} from '../../../config'

//function for deleted one measure.
let deleteObject = async(user, method, id) => {
    return new Promise((resolve, reject) => {   
        let uri = REACT_APP_AWS_API + method + id;
        if (user != null && user != undefined && user != "") {
            uri = REACT_APP_AWS_API + method + "/" + id + "/" + user?.profile + "/" + user?.locale;
        }
    
        axios.delete(uri).then(response=> {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    })
}

export default deleteObject