import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Box from '@mui/material/Box';
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ButtonHeader from '../../../Components/Buttons/ButtonHeader'
import ButtonAction from '../../../Components/Buttons/ButtonAction'
import ButtonSend from '../../../Components/Buttons/ButtonSend'
import ButtonCancel from '../../../Components/Buttons/ButtonCancel'
import Pagination from '../../../Components/Table/Utils/Pagination'
import CsLoader from '../../../Components/Loading/Loader'
import Alert from '../../../Components/Alerts/message'

import { getFilteredMaintenance } from './Business/maintenanceget';
import { postMaintenance } from './Business/maintenancepost';
// import PostMaintenance from '../../../../Controller/Custom/Maintenance/post'
import PutMaintenance from '../../../../Controller/Custom/Maintenance/put'
import AttributesMaintenance from '../../../../Controller/Custom/Maintenance/selectdata'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Maintenance.css'
import { deleteMaintenanceByType } from './Business/maintenancedelete';

class Maintenance extends Component {
    constructor(props) {
        super(props);
        this.state={
            isloading: false,
            disabled: false,
            loading: false,
            pagination: {
                currentpage: 1,
                recordperpage: 10
            },
            alerts: {
                open: false,
                severity: '',
                message: ''
            },
            modals:{
                iscreatemodalopen: false,
                isupdatemodalopen: false,
                isdeletemodalopen: false,
                deletemodal: {
                    title: '',
                    message: ''
                },
            },
            user: props?.user,
            ssdata:{
                id: '',
                value: '',
                name: ''
            },
            gsdata:{
                attributes:[]
            },
            getdata:[],
            createdata:{
                type: '',
                attribute: '',
                user: '',
                date: new Date()
            },
            selectdata: {
                id: '',
                code: ''
            }    
        };

        this.wrapper = React.createRef();
        this.handleSLChange = this.handleSLChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
      }

    openModalInsert=()=>{
        this.setState({iscreatemodalopen: !this.state.iscreatemodalopen});
    }

    openModalUpdate=()=>{
        this.setState({isupdatemodalopen: !this.state.isupdatemodalopen});
    }

    getDataByType=async(type)=> {
        if(type !== null) {
            let response = await getFilteredMaintenance(type);
            this.setState({
                getdata: response,
                isloading: false
            });
        }
    }

    postData=async()=>{
        this.setState({disabled: true, loading: true}); 
        
        let response = await postMaintenance(this.state.createdata, this.state.user)
        this.setState({alerts: {
            open: true,
            severity: response.severity,
            message: response.message,
         }});
         
        if(this.state.ssdata.id !== null && this.state.ssdata.id != '') {
            this.getDataByType(this.state.createdata.type);
        }

        this.setState({disabled: false, loading: false}); 
        this.openModalInsert();
    }

    putData=async()=>{
        this.setState({disabled: true, loading: true}); 
        
        let putresponse = await PutMaintenance(this.state.createdata, this.state.selectdata.id, this.state.user)
        if(putresponse.status !== 200){
            this.setState({alerts: {
                open: true,
                severity: 'error',
                message: putresponse.data.mensaje,
             }});
        }else{
            this.setState({alerts: {
                open: true,
                severity: 'success',
                message: putresponse.data.mensaje,
             }});
        }
        if(this.state.ssdata.id != null && this.state.ssdata.id != '') {
            this.getDataByType(this.state.createdata.type);
        }

        this.setState({disabled: false, loading: false}); 
        this.openModalUpdate();
    }

    handleSLChange=async(event)=> {
        if(event !== null) {
            this.setState({
                ssdata: {
                    id: event.target.value
                },
                isloading: true
            });

            if(event.target.value !== null) {
                this.getDataByType(event.target.value);
            }
        }
    }

    handleFormChange=async e=>{
        await this.setState({
            createdata:{
            ...this.state.createdata,
            [e.target.name]: e.target.value
          }
        });
        console.log(this.state.createdata);
    }

    handleCloseAlert() {
        this.setState({alerts: {open: false }});
    }

    // select data period
    selectData=(data)=>{
        if(data !== null) {
            this.setState({
                selectdata: {
                  id: data.Id,
                  code: data.Type
                },
                createdata: {
                    type: data.Type,
                    attribute: data.Nombre,
                    user: 'Test',
                    date: new Date()
                }
              });
      
              console.log(this.state.selectdata);
        }
    }

    // Delete data selected
    deleteData=async()=>{
        if(this.state.selectdata !== null) {
            let response = await deleteMaintenanceByType(this.state.selectdata, this.state.user);
            this.setState({
                alerts: {
                    open: response.open,
                    severity: response.severity,
                    message: response.message
                },
                modals: {isdeletemodalopen: false}
            });
    
            if(this.state.selectdata !== null) {
                this.getDataByType(this.state.selectdata.code);
            }
        }
    }

    // render pagination functionality for data table.
    pageRender(page) {
        this.setState({
            pagination: {
                currentpage: page
            }
        });
    }

    updateUser=(data)=> {
        this.setState({user: data});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.user != this.state.user) {
            this.updateUser(prevProps.user);
        }
    }

    // Initialize primary components necesary for interact with data.
    componentDidMount=async()=>{
        this.setState({
            gsdata: {
                attributes: AttributesMaintenance
            }     
        });
    }
    
    render(){
        const indexOfLastRecord = this.state.pagination.currentpage * 10;
        const indexOfFirstRecord = indexOfLastRecord - 10;
        const currentData = this.state.getdata.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.getdata.length / 10);

        return(
            <div ref={this.wrapper}>
                <div className='mb-bt15'>
                    <Box sx={{ maxWidth: 300 }}>
                        <FormControl fullWidth>
                            <InputLabel id="globallabel">Attributes</InputLabel>
                            <Select
                                labelId="globallabel"
                                id="global"
                                value={this.state.ssdata.id}
                                label="Global"
                                onChange={this.handleSLChange}
                                >
                                {this.state.gsdata.attributes.map((attribute, index)=> {
                                    return(
                                        <MenuItem key={index} value={attribute.Code ?? ""}>{attribute.Name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div>
                    <div className='mb-10 txt-righ'>
                        <ButtonHeader event={()=>{this.setState({createdata: null}); this.openModalInsert()}} name="Attribute" />
                    </div>
                </div>
                <div>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th className='th-id'>Id</th>
                                <th className='th-month'>Platform Attribute name</th>
                                <th className='th-action'>Action</th>
                            </tr>
                        </thead>
                        <tbody>	
                            {this.state.isloading === false
                                ? (currentData.length > 0 ?
                                    currentData.map((component, index)=> {
                                        return(
                                            <tr key={index}>
                                                <td>{component.Id}</td>
                                                <td>{component.Nombre}</td>
                                                <td>
                                                <ButtonAction
                                                        canEdit={true}
                                                        canDelete={this.state.user?.profile == "Admin" || this.state.user?.profile == "Corporate User"}
                                                        canSeeDetails={false}
                                                        editRow={()=> {this.selectData(component); this.openModalUpdate(); }}
                                                        deleteRow={()=>{this.selectData(component); this.setState({modals: {isdeletemodalopen: true} })}}
                                                        detailRow={''} />
                                                </td>
                                            </tr>
                                        )   
                                    }) : <tr><td colSpan={6} style={{borderBottom: "0px", textAlign: "left" }}><div style={{background:'white'}}>Not have registers.</div></td></tr>)
                                : <CsLoader />
                            }

                        </tbody>
                    </table>
                    <Pagination pagesCount={nPages} currentPage={this.state.pagination.currentpage} setCurrentPage={this.pageRender.bind(this)}/>

                    <Modal isOpen={this.state.iscreatemodalopen}>
                        <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                            <span style={{float: 'right'}} onClick={()=>this.openModalInsert()}>x</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <div className='mb-bt15'>
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                        fullWidth
                                                        id="attribute"
                                                        name="attribute"
                                                        variant="filled"
                                                        label="Name of attribute"
                                                        defaultValue={this.state.createdata?.attribute}
                                                        onChange={this.handleFormChange}
                                                        />
                                            </FormControl>
                                        </Box>
                                    </div> 
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth variant="filled">
                                                <InputLabel id="globallabel">Attributes</InputLabel>
                                                <Select
                                                    labelId="globallabel"
                                                    id="type"
                                                    name="type"
                                                    value={this.state.createdata?.type}
                                                    onChange={this.handleFormChange}
                                                    >
                                                    {this.state.gsdata.attributes.map((attr, index)=> {
                                                        return(
                                                            <MenuItem key={index} value={attr.Code ?? ""}>{attr.Name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        <ButtonSend event={()=>this.postData()} disabled={this.state.disabled} loading={this.state.loading} name="Send" />
                            <ButtonCancel event={()=>this.openModalInsert()} name="Cancel" />
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.isupdatemodalopen}>
                        <ModalHeader style={{display: 'block', backgroundColor: '#081c42', color: 'white'}}>
                            <span style={{float: 'right'}} onClick={()=>this.openModalUpdate()}>x</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <div className='mb-bt15'>
                                    <div className='mb-10'>
                                        <Box sx={{ maxWidth: '100%' }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                        fullWidth
                                                        id="attribute"
                                                        name="attribute"
                                                        variant="filled"
                                                        label="Name of attribute"
                                                        defaultValue={this.state.createdata?.attribute}
                                                        onChange={this.handleFormChange}
                                                        />
                                            </FormControl>
                                        </Box>
                                    </div> 
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonSend event={()=>this.putData()} disabled={this.state.disabled} loading={this.state.loading} name="Update" />
                            <ButtonCancel event={()=>this.openModalUpdate()} name="Cancel" />
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modals.isdeletemodalopen}>
                        <ModalBody>
                            Are you sure that you want delete the following component?
                        </ModalBody>
                        <ModalFooter>
                        <button className="btn btn-danger" onClick={()=>this.deleteData()}>Yes</button>
                        <button className="btn btn-secundary" onClick={()=>this.setState({modals: {isdeletemodalopen: false} })}>No</button>
                        </ModalFooter>
                    </Modal>
                    <Alert open={this.state.alerts.open} severity={this.state.alerts.severity} message={this.state.alerts.message} event={this.handleCloseAlert.bind(this)}/>
                </div>
            </div>
        )
    }
}

export default Maintenance