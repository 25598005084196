import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#162C5E',
  width: '140px',
  borderRadius: '4px',
  backgroundColor: 'white',
  textTransform: 'none',
  border: '0.5px solid #e8e8e8;',
  '&:hover': {
    backgroundColor: 'white',
    textTransform: 'none',
  },
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontWeight: 'bold' 
}));

export default function ButtonCancel(prop) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton endIcon={<CancelScheduleSendIcon />} variant="contained" onClick={prop.event}>{prop.name}</ColorButton>
    </Stack>
  );
}