import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, ProSidebarProvider } from "react-pro-sidebar";
import SettingsIcon from '@mui/icons-material/Settings';
import './internal-sidebar-menu.css';

let Sidebar = () => { 
    const [activeIndex, setActiveIndex] = useState(() => { 
        return window.location.pathname === '/DataBase' ? 0 : 0;
    });
    
    return(
        <div className="internal-sidebar">
            <ProSidebarProvider >
                <div className='internal-sidebar-text'>
                    <b>Options</b>
                </div>
                <Menu>
                    <MenuItem active={activeIndex === 0} icon={<SettingsIcon />} onClick={() => setActiveIndex(0)}>
                        MTO Data Base
                    </MenuItem>
                </Menu>
            </ProSidebarProvider >
        </div>
    )
}

export default Sidebar;