import React, { useState, useEffect } from 'react';
import Period from './Pages/Period'
import './Container.css';

const Container=(props)=>{
  const [user, setUser] = useState(props?.user);

  useEffect(() => {
    setUser(props?.user)
  });

  return (
    <div className="App">
        <div>
            <div className='request-form'>
              {user ? <Period user={user} /> : ''}
            </div>
        </div>
    </div>
  );
}

export default Container;